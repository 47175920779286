import { EmptyState } from '@brightlayer-ui/react-components';
import { ErrorOutline } from '@mui/icons-material';
import React from 'react';

const WidgetNotConfigured = ({
    title,
    iconComponent,
}: {
    title?: string;
    iconComponent?: React.JSX.Element;
}): JSX.Element => (
    <EmptyState
        icon={iconComponent ?? <ErrorOutline className="height-100 width-100" />}
        title={title ?? 'Widget Not Configured'}
        className="padding-y-80"
    />
);

export default WidgetNotConfigured;
