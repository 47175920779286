import React from 'react';
import './CustomLegend.css';

const CustomLegend = ({
    chartType,
    isForecast,
    isDayNight = false,
}: {
    chartType: string;
    isForecast: boolean;
    isDayNight?: boolean;
}): React.JSX.Element => {
    const handleLegendItems = (): Array<{ label: string; svg: React.JSX.Element }> => {
        const items: Array<{ label: string; svg: React.JSX.Element }> = [];

        if (chartType === 'line') {
            // Add Delta item
            items.push({
                label: 'Delta',
                svg: (
                    <svg width="40" height="10">
                        <line x1="0" y1="5" x2="40" y2="5" stroke="#424E54" strokeWidth="3.5" strokeDasharray="5,5" />
                    </svg>
                ),
            });
            if (isForecast) {
                items.push({
                    label: 'Forecast data',
                    svg: (
                        <svg width="24" height="10">
                            <line
                                x1="0"
                                y1="5"
                                x2="24"
                                y2="5"
                                stroke="#424E54"
                                strokeWidth="2.5"
                                strokeDasharray="2,2"
                            />
                        </svg>
                    ),
                });
            }
        } else if (chartType === 'bar' && isForecast) {
            items.push({
                label: 'Forecast data',
                svg: (
                    <>
                        <svg width="12" height="24">
                            <rect width="12" height="24" fill="#4DA3D4" />
                        </svg>
                        <svg width="12" height="24">
                            <rect width="12" height="24" fill="#DBEEF2" />
                        </svg>
                    </>
                ),
            });
        }
        if (isDayNight) {
            items.push(
                {
                    label: 'Day',
                    svg: (
                        <svg width="24" height="24">
                            <rect width="24" height="24" fill="#FDF9E6" />
                        </svg>
                    ),
                },
                {
                    label: 'Night',
                    svg: (
                        <svg width="24" height="24">
                            <rect width="24" height="24" fill="#F1F8FA" />
                        </svg>
                    ),
                }
            );
        }
        return items;
    };

    return (
        <div className="custom-legend">
            {handleLegendItems().map((item, index) => (
                <div key={index} className="legend-item">
                    {item.svg}
                    <span className="legend-label">{item.label}</span>
                </div>
            ))}
        </div>
    );
};

export default CustomLegend;
