import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleAssetTypes, handleChannelRemove, handleReallocateChannels } from './helpers';
import { RootState } from '../../app/store';

const metricsInitialState: any = {
    secondary: [],
    assetTypes: {},
    assets: [],
    viewTitleBar: true,
    viewTimePeriod: true,
    selectedTimePeriod: 'today',
};

const metricsWidgetSlice = createSlice({
    name: 'metrics',
    initialState: metricsInitialState,
    reducers: {
        resetMetricsConfiguration: () => {
            return metricsInitialState;
        },
        setSelectedMetricsAssets: (state, action) => {
            const { id } = action.payload;

            const existingId = state.assets.findIndex((el: any) => el.id === id);
            if (existingId !== -1) {
                state.assets.splice(existingId, 1);
            } else {
                state.assets.push(action.payload);
            }
        },
        setMetricAssetTypes: (state: RootState, action: PayloadAction<any>) => {
            handleAssetTypes(state, action);
        },
        setPredefinedMetrics: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'assetTypes') {
                    state[key] = action.payload[key];
                }
            });
        },
        setMetricsData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload ?? [];
        },
        reAllocateAssetForMetrics: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },

        removeSelectedAssetForMetrics: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
        setMetricsPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
            state['assets'] = [];
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                state.secondary = [];
                state['assets'] = [];
            }
        });
    },
});

export const {
    setSelectedMetricsAssets,
    reAllocateAssetForMetrics,
    removeSelectedAssetForMetrics,
    setMetricAssetTypes,
    resetMetricsConfiguration,
    setPredefinedMetrics,
    setMetricsData,
    setMetricsPreferences,
} = metricsWidgetSlice.actions;
export default metricsWidgetSlice;
