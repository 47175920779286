import React from 'react';
import { ChevronLeft, ChevronRight, KeyboardArrowDown, TrendingDown, TrendingUp } from '@mui/icons-material';
import {
    Box,
    Breadcrumbs,
    Card,
    CardContent,
    Collapse,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    List,
    MenuItem,
    Select,
    Stack,
    Typography,
    Skeleton,
} from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { LineChart, StackedBarChart } from './AggregatedChart';
import Checkbox from '@mui/material/Checkbox';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import WidgetNotConfigured from 'pages/EditWidget/WIdgetNotConfigured';
import { useTypedSelector } from '@fiji/common';
import { useGetAllDevicesQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { areAllDataArraysEmpty } from 'utils/helpers';
import CustomLegend from './ChartLegends';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { UserProfile } from '@fiji/common/src/types';
import { systemTimezone } from '@fiji/common/src/utils/helpers';

type LegendItem = {
    name: string;
    checked: boolean;
};
const defaultColors = [
    '#f5db6d', // Yellow
    '#4d8ec4', // Blue
    '#e09c66', // Rust
    '#d0d96c', // Citron
    '#abd7e1', // Sky
    '#79ae72', // Emerald
    '#f5c462', // Gold
    '#feeeb3', // Butter
    '#4dc9c3', // Teal
    '#bfa55e', // Toad
    '#f8d270', // Goldenrod
    '#d3e1bc', // Sage
    '#6d9485', // Pine
    '#4d7e9c', // Navy
    '#da7777', // Red
    '#f7a064', // Orange
    '#f1b16b', // Sunset
    '#ba5670', // Wine
    '#e57068', // Crimson
    '#4dacf6', // Light Blue
    '#b779f4', // Purple
    '#74cc63', // Green
    '#f6ca70', // Trophy
];

const legendColor = [
    '#fbefc1', // Yellow
    '#b3cfe6', // Blue
    '#f0d5bd', // Rust
    '#ebefc0', // Citron
    '#dbeef2', // Sky
    '#c6dcc2', // Emerald
    '#fbe6bc', // Gold
    '#fff8df', // Butter
    '#b3e8e5', // Teal
    '#e3d8ba', // Toad
    '#fcecc2', // Goldenrod
    '#ecf2e2', // Sage
    '#c0d1cb', // Pine
    '#b3c8d5', // Navy
    '#efc5c5', // Red
    '#fcd6bc', // Orange
    '#f9ddbf', // Sunset
    '#e1b7c2', // Wine
    '#f4c2b3', // Crimson
    '#b3dbfb', // Light Blue
    '#e0c5fa', // Purple
    '#c4e9bc', // Green
    '#fbe8c2', // Trophy
];

const processData = (rawData: any): any[] => {
    const data = rawData ?? [];

    const uniqueNames = [...new Set(data?.map((item: { name?: string }) => item?.name?.toLowerCase()))];
    const colorMap = new Map(
        uniqueNames.map((name, index) => [
            name,
            {
                color: name === 'delta' ? 'black' : defaultColors?.[index % (defaultColors?.length ?? 1)],
                legendBgColor: name === 'delta' ? 'gray' : legendColor?.[index % (legendColor?.length ?? 1)],
            },
        ])
    );

    return data?.map((item: { name?: string }) => {
        const lowercaseName = item?.name?.toLowerCase();
        const { color, legendBgColor } = colorMap.get(lowercaseName) ?? {};
        return {
            ...item,
            color,
            legendBgColor,
        };
    });
};

const calculateTimePeriod = (startTime: number, endTime: number, selectedTimePeriod: string): string => {
    const MS_PER_DAY = 24 * 60 * 60 * 1000; // 86,400,000 milliseconds in a day
    const MS_PER_WEEK = 7 * MS_PER_DAY; // 604,800,000 milliseconds in a week
    const MS_PER_MONTH = 31 * MS_PER_DAY; // 2,419,200,000 milliseconds in 28 days

    const timeDiff = endTime - startTime;
    const isLessThanWeek = timeDiff < MS_PER_WEEK;
    const isOneDay = timeDiff <= MS_PER_DAY;
    const isLessThanMonth = timeDiff <= MS_PER_MONTH;

    let timePeriod = selectedTimePeriod;

    if (selectedTimePeriod === 'custom') {
        switch (true) {
            case isOneDay:
                timePeriod = 'today';
                break;
            case isLessThanWeek:
                timePeriod = 'week';
                break;
            case isLessThanMonth:
                timePeriod = 'month';
                break;
            case timeDiff > MS_PER_MONTH:
                timePeriod = 'year';
                break;
            default:
                break;
        }
    }

    return timePeriod;
};

const Component = (props: any): JSX.Element => {
    const { data: profileDetails } = useGetUserProfileQuery({}) as ApiEndpointType<ApiResponseType<UserProfile>>;
    const dateFormat = profileDetails?.data?.dateFormat ?? 'MM/DD/YYYY';
    const dataClone = JSON.parse(JSON.stringify(props?.widgetData?.secondary ?? {}));
    const modifiedChartData = {
        ...dataClone,
        series: processData(dataClone?.series),
    };

    const [unit, setUnit] = React.useState(props?.widgetData?.unitType);
    const [data, setData] = React.useState<any>({});
    const [lineChartData, setLineChartData] = React.useState<any>({});
    const [consumptionSources, setConsumptionSources] = React.useState<LegendItem[]>([]);
    const [supplySources, setSupplySources] = React.useState<LegendItem[]>([]);
    const [openSideBar, setOpenSideBar] = React.useState(false);
    const [checked, setChecked] = React.useState<any>([]);
    //for line chart legend
    const midIndex = Math.ceil(modifiedChartData?.series?.length / 2);
    const rightItems = modifiedChartData?.series?.slice(0, midIndex);
    const leftItems = modifiedChartData?.series?.slice(midIndex);
    const [expandedItems, setExpandedItems] = React.useState<{ [key: string]: boolean }>({});
    const [filter, setFilter] = React.useState({});
    const selectedNode = useTypedSelector((state: any) => state?.common?.selectedNode);
    const { currentData: assetsList, isFetching: isAssetListFetching } = useGetAllDevicesQuery(
        {
            page: 0,
            size: 10,
            filters: filter,
        },
        { refetchOnMountOrArgChange: true, skip: Object.keys(filter).length === 0 }
    );
    const handleExpandClick = (id: string, type: string): void => {
        setExpandedItems((prevState) => ({
            [`${id}-${type}`]: !prevState[`${id}-${type}`],
        }));

        setFilter((prev: any) => ({
            ...prev,
            group: [selectedNode?.id],
            type: [id],
        }));
    };
    const handleCheckboxChange = (name: string, type: 'consumption' | 'supply' | 'line'): void => {
        if (type === 'consumption') {
            setConsumptionSources((prevState) =>
                prevState?.map((item) => (item.name === name ? { ...item, checked: !item.checked } : item))
            );
        } else if (type === 'line') {
            setChecked((prevChecked: any) => ({
                ...prevChecked,
                [name]: !prevChecked[name],
            }));
        } else {
            setSupplySources((prevState) =>
                prevState?.map((item) => (item.name === name ? { ...item, checked: !item.checked } : item))
            );
        }
    };

    const renderAssetsList = (): React.JSX.Element =>
        isAssetListFetching ? (
            <>
                <Stack className="border-bottom-1" py={2} pl={10}>
                    <Skeleton variant="text" animation="wave" width={'200px'} />
                    <Skeleton variant="text" animation="wave" width={'50px'} />
                </Stack>
                <Stack className="border-bottom-1" py={2} pl={10}>
                    <Skeleton variant="text" animation="wave" width={'200px'} />
                    <Skeleton variant="text" animation="wave" width={'50px'} />
                </Stack>
            </>
        ) : (
            <List disablePadding>
                {assetsList?.data?.records?.map((asset: any) => (
                    <InfoListItem
                        key={asset.id}
                        title={asset.name}
                        subtitle={
                            (
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Breadcrumbs separator="<" aria-label="breadcrumb">
                                        {asset?.groupPath?.map((path: any) => (
                                            <Link
                                                underline="none"
                                                key={asset.id}
                                                className="black-500 f-14 fw-600 cursor-pointer"
                                            >
                                                {path}
                                            </Link>
                                        ))}
                                    </Breadcrumbs>
                                </Stack>
                            ) as any
                        }
                        divider={openSideBar ? 'full' : 'partial'}
                    />
                ))}
            </List>
        );

    const renderConsumptionList = (): React.JSX.Element[] => {
        const consumptionList = modifiedChartData?.series
            ?.filter(
                (item: any) =>
                    item.stack === 'consumption' && item?.data?.some((el: number[]) => el[1] !== 0 && el[1] !== null)
            )
            ?.map((item: any) => (
                <>
                    <InfoListItem
                        key={`${item.name}-${item.supply}`}
                        title={item.name}
                        divider="full"
                        icon={
                            <Checkbox
                                sx={{
                                    '&.Mui-checked': {
                                        color: item.color,
                                    },
                                }}
                                defaultChecked
                                onClick={(): void => handleCheckboxChange(item.name, 'consumption')}
                            />
                        }
                        rightComponent={
                            props?.mode === 'viewAll' ? (
                                <IconButton onClick={(): void => handleExpandClick(item.id, 'consumption')}>
                                    <KeyboardArrowDown
                                        className={`color-content ${
                                            expandedItems[`${item.id}-consumption`] ? 'rotated' : 'not-rotated'
                                        }`}
                                    />
                                </IconButton>
                            ) : (
                                <></>
                            )
                        }
                        statusColor={item.color}
                        backgroundColor={item.legendBgColor}
                    />
                    {expandedItems[`${item.id}-consumption`] && renderAssetsList()}
                </>
            ));
        return consumptionList;
    };

    const renderSupplyList = (): React.JSX.Element[] => {
        const supplyList = modifiedChartData?.series
            ?.filter(
                (item: any) =>
                    item.stack === 'supply' && item?.data?.some((el: number[]) => el[1] !== 0 && el[1] !== null)
            )
            ?.map((item: any) => (
                <>
                    <InfoListItem
                        key={`${item.name}-${item.supply}`}
                        title={item.name}
                        divider="full"
                        icon={
                            <Checkbox
                                sx={{
                                    '&.Mui-checked': {
                                        color: item.color,
                                    },
                                }}
                                defaultChecked
                                onClick={(): void => handleCheckboxChange(item.name, 'supply')}
                            />
                        }
                        rightComponent={
                            props?.mode === 'viewAll' ? (
                                <IconButton onClick={(): void => handleExpandClick(item.id, 'supply')}>
                                    <KeyboardArrowDown
                                        className={`color-content ${
                                            expandedItems[`${item.id}-supply`] ? 'rotated' : 'not-rotated'
                                        }`}
                                    />
                                </IconButton>
                            ) : (
                                <></>
                            )
                        }
                        statusColor={item.color}
                        backgroundColor={item.legendBgColor}
                    />
                    {expandedItems[`${item.id}-supply`] && renderAssetsList()}
                </>
            ));

        return supplyList;
    };

    React.useEffect(() => {
        if (props?.widgetData?.secondary?.series?.length) {
            const initialData = JSON.parse(JSON.stringify(processData(props?.widgetData?.secondary?.series)));
            const updatedData = initialData?.filter(
                (series: { name: string }) => series.name === 'Delta' || checked?.[series.name]
            );
            setLineChartData({ ...props?.widgetData?.secondary, series: updatedData });
        }
    }, [checked, props?.widgetData?.secondary]);
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const renderLineChartLegendList = (lineData: any[]) =>
        lineData?.map(
            (item: any) =>
                item.name !== 'Delta' && (
                    <>
                        <InfoListItem
                            key={item.name}
                            title={item.name}
                            divider="full"
                            icon={
                                <Checkbox
                                    sx={{
                                        '&.Mui-checked': {
                                            color: item.color,
                                        },
                                    }}
                                    defaultChecked
                                    onClick={(): void => handleCheckboxChange(item.name, 'line')}
                                />
                            }
                            rightComponent={
                                props?.mode === 'viewAll' ? (
                                    <IconButton onClick={(): void => handleExpandClick(item.id, 'line')}>
                                        <KeyboardArrowDown
                                            className={`color-content ${
                                                expandedItems[`${item.id}-line`] ? 'rotated' : 'not-rotated'
                                            }`}
                                        />
                                    </IconButton>
                                ) : (
                                    <></>
                                )
                            }
                            statusColor={item.color}
                            backgroundColor={item.legendBgColor}
                        />
                        {expandedItems[`${item.id}-line`] && renderAssetsList()}
                    </>
                )
        );
    const handleSideBar = (): void => {
        setOpenSideBar((prev) => !prev);
        setExpandedItems({});
    };

    React.useEffect(() => {
        const getFilteredSeries = (): void => {
            const consumptionChecked = consumptionSources?.filter((item) => item.checked).map((item) => item.name);
            const supplyChecked = supplySources?.filter((item) => item.checked).map((item) => item.name);
            setData({
                ...props?.widgetData?.secondary,
                series:
                    processData(dataClone?.series)?.filter((series: { stack: string; name: any }) => {
                        if (series.stack === 'consumption') {
                            return consumptionChecked.includes(series.name ?? '');
                        }
                        if (series.stack === 'supply') {
                            return supplyChecked.includes(series.name ?? '');
                        }
                        return false;
                    }) ?? [],
            });
        };
        if ((consumptionSources?.length || supplySources?.length) && props?.widgetData?.type === 'bar')
            getFilteredSeries();
    }, [consumptionSources, supplySources]);

    React.useEffect(() => {
        if (
            (props?.chartType && props.chartType === 'bar') ||
            (!props?.chartType && props?.widgetData?.type === 'bar')
        ) {
            setSupplySources(
                modifiedChartData?.series
                    ?.filter(
                        (series: { stack?: string; data?: any[] }) =>
                            series?.stack === 'supply' && series?.data?.some((el) => el[1] !== 0 && el[1] !== null)
                    )
                    ?.map((series: { name?: string }) => ({ name: series.name, checked: true })) ?? []
            );
            setConsumptionSources(
                modifiedChartData?.series
                    ?.filter(
                        (series: { stack?: string; data?: any[] }) =>
                            series?.stack === 'consumption' && series?.data?.some((el) => el[1] !== 0 && el[1] !== null)
                    )
                    ?.map((series: { name?: string }) => ({ name: series.name, checked: true })) ?? []
            );
        } else if (
            (props?.chartType && props.chartType === 'line') ||
            (!props?.chartType && props?.widgetData?.type === 'line')
        ) {
            setChecked(
                props?.widgetData?.secondary?.series?.reduce(
                    (acc: { [x: string]: boolean }, curr: { name: string }) => {
                        if (curr.name !== 'Delta') {
                            acc[curr.name] = true;
                        }
                        return acc;
                    },
                    {}
                )
            );
        }
    }, [props?.widgetData?.secondary]);

    return (
        <Card sx={{ height: '100%' }}>
            <>
                {props?.widgetData?.viewTitleBar && props?.mode !== 'viewAll' && (
                    <WidgetHeader
                        mode={props?.mode}
                        widgetData={props?.widgetData}
                        widgetFilter={props?.widgetFilter}
                        downloadCSVHandler={props?.downloadCSVHandler}
                    />
                )}
                {props?.mode !== 'edit' && props?.mode !== 'viewAll' && <Divider />}
            </>
            <CardContent className="padding-0">
                <Stack pt={2}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                        {props?.mode !== 'viewAll' && (
                            <FormControl variant="standard" sx={{ minWidth: 78, mr: 3 }}>
                                <InputLabel id="demo-simple-select-standard-label" disabled={props?.mode !== 'view'}>
                                    Units
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={modifiedChartData?.unit ?? unit}
                                    onChange={(event): void => {
                                        props?.unitFilter(event, props?.widgetData?.id);
                                        setUnit(event.target.value);
                                    }}
                                    disabled={props?.mode !== 'view'}
                                    autoWidth
                                    disableUnderline
                                >
                                    <MenuItem value="kWh">(kWh) Kilowatts per hour</MenuItem>
                                    <MenuItem value="W">(W) Watts</MenuItem>
                                    <MenuItem value="V">(V) Volts</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Stack>

                    {!areAllDataArraysEmpty(props?.widgetData?.secondary?.series) ? (
                        <Stack direction={props?.mode !== 'viewAll' ? 'column' : 'row'}>
                            <Stack className={`${openSideBar ? 'w-80' : 'w-100'}`} data-test-id="aggregated-chart">
                                {props?.chartType === 'bar' ||
                                (!props?.chartType && props?.widgetData?.type === 'bar') ? (
                                    <>
                                        <StackedBarChart
                                            chartData={data}
                                            selectedTimePeriod={calculateTimePeriod(
                                                props?.widgetData?.startTime,
                                                props?.widgetData?.endTime,
                                                props?.widgetData?.selectedTimePeriod
                                            )}
                                            dateFormat={dateFormat}
                                            timeZone={props?.widgetData?.timeZone ?? systemTimezone}
                                        />
                                        <CustomLegend
                                            chartType="bar"
                                            isForecast={
                                                props?.widgetData?.selectedTimePeriod === 'today' ||
                                                props?.widgetData?.selectedTimePeriod === 'week'
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <LineChart
                                            chartData={lineChartData}
                                            selectedTimePeriod={calculateTimePeriod(
                                                props?.widgetData?.startTime,
                                                props?.widgetData?.endTime,
                                                props?.widgetData?.selectedTimePeriod
                                            )}
                                            dateFormat={dateFormat}
                                            timeZone={props?.widgetData?.timeZone ?? systemTimezone}
                                        />
                                        <CustomLegend
                                            chartType="line"
                                            isForecast={props?.widgetData?.selectedTimePeriod === 'today'}
                                        />
                                    </>
                                )}
                            </Stack>

                            {props?.mode !== 'viewAll' ? (
                                <Stack mt={3} direction={'row'} className="flex-100">
                                    {props?.widgetData?.type === 'bar' ? (
                                        <>
                                            <Stack className="flex-50">
                                                <Stack
                                                    direction={'row'}
                                                    spacing={1}
                                                    alignItems={'center'}
                                                    py={1}
                                                    pl={2}
                                                >
                                                    <TrendingDown color="error" />
                                                    <Typography variant="body1">Consumption Sources</Typography>
                                                </Stack>
                                                <List disablePadding>{renderConsumptionList()}</List>
                                            </Stack>
                                            <Stack className="flex-50">
                                                <Stack
                                                    direction={'row'}
                                                    spacing={1}
                                                    alignItems={'center'}
                                                    py={1}
                                                    pr={2}
                                                >
                                                    <TrendingUp color="success" />
                                                    <Typography variant="body1">Supply Sources</Typography>
                                                </Stack>
                                                <List disablePadding>{renderSupplyList()}</List>
                                            </Stack>
                                        </>
                                    ) : (
                                        <>
                                            <Stack className="flex-50">
                                                <List disablePadding>{renderLineChartLegendList(leftItems)}</List>
                                            </Stack>
                                            <Stack className="flex-50">
                                                <List disablePadding>{renderLineChartLegendList(rightItems)}</List>
                                            </Stack>
                                        </>

                                        //     <Box display="flex" justifyContent="space-between">
                                        //     <List disablePadding>
                                        //       {leftItems.map((item, index) => (
                                        //         <ListItem key={index}>{item}</ListItem>
                                        //         <List disablePadding>{renderLineChartLegendList()}</List>
                                        //       ))}
                                        //     </List>
                                        //     <List disablePadding>
                                        //       {rightItems.map((item, index) => (
                                        //         <ListItem key={index}>{item}</ListItem>
                                        //       ))}
                                        //     </List>
                                        //   </Box>
                                    )}
                                </Stack>
                            ) : (
                                <Stack direction={'row'} justifyContent={'flex-end'}>
                                    <Box className="bg-white border-left-1">
                                        <Collapse orientation="horizontal" in={openSideBar} collapsedSize={70}>
                                            <Stack p={2} className="border-bottom-1">
                                                {openSideBar ? (
                                                    <Stack direction={'row'} spacing={4}>
                                                        <ChevronRight
                                                            onClick={handleSideBar}
                                                            className="cursor-pointer"
                                                        />
                                                        <Typography
                                                            variant="subtitle2"
                                                            fontSize={'14px'}
                                                            fontWeight={'600'}
                                                            className="text-primary"
                                                        >
                                                            Assets Groups
                                                        </Typography>
                                                    </Stack>
                                                ) : (
                                                    <ChevronLeft onClick={handleSideBar} className="cursor-pointer" />
                                                )}
                                            </Stack>
                                            <Stack
                                                sx={{ height: 'calc(100vh - 304px)', overflowY: 'scroll' }}
                                                className={`${openSideBar ? 'width-400' : ''}`}
                                            >
                                                {props?.widgetData?.type === 'bar' ? (
                                                    <>
                                                        <InfoListItem
                                                            title={
                                                                <Typography variant="body1">
                                                                    Energy Consumption
                                                                </Typography>
                                                            }
                                                            divider="full"
                                                            icon={<TrendingDown color="error" />}
                                                        />
                                                        <List disablePadding>{renderConsumptionList()}</List>
                                                        <InfoListItem
                                                            title={
                                                                <Typography variant="body1">Energy Supply</Typography>
                                                            }
                                                            divider="full"
                                                            icon={<TrendingUp color="success" />}
                                                        />
                                                        <List disablePadding>{renderSupplyList()}</List>
                                                    </>
                                                ) : (
                                                    <List disablePadding>
                                                        {renderLineChartLegendList(modifiedChartData?.series)}
                                                    </List>
                                                )}
                                            </Stack>
                                        </Collapse>
                                    </Box>
                                </Stack>
                            )}
                        </Stack>
                    ) : (
                        <WidgetNotConfigured
                            title={
                                props?.widgetData?.multiDevice && props?.widgetData?.assets?.length === 0
                                    ? 'Widget Not Configured'
                                    : (areAllDataArraysEmpty(props?.widgetData?.secondary?.series) &&
                                          !props?.widgetData?.multiDevice) ||
                                      props?.widgetData?.assets?.length
                                    ? 'No Data Found'
                                    : undefined
                            }
                        />
                    )}
                </Stack>
            </CardContent>

            {props?.widgetData.viewAllButton && props?.mode !== 'viewAll' && (
                <WidgetFooter
                    mode={props?.mode}
                    widgetData={props?.widgetData}
                    ids={props?.widgetData?.ids}
                    disabled={!(Object.keys(modifiedChartData || {})?.length && modifiedChartData?.series?.length)}
                />
            )}
        </Card>
    );
};

export default Component;
