import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Stack, Breadcrumbs, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route } from '../router/routes';
import { Spacer } from '@brightlayer-ui/react-components';
import Close from '@mui/icons-material/Close';
import { RootState, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HeaderActions } from './HeaderActions';
import { useNavigationBack } from 'hooks';
import { CustomTransComponent } from './';

/* The `InternalPageHeader` component is a React functional component that renders a header for an
internal page. It takes in three props: `nestedRoutesList`, `title`, and `page`. */
export const InternalPageHeader = (props: {
    nestedRoutesList: Route[];
    title: string;
    page: JSX.Element;
    extraProps: Route;
    isServiceAdmin?: boolean;
}): JSX.Element => {
    const { nestedRoutesList, title, page, extraProps, isServiceAdmin } = props;
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const navigationBack = useNavigationBack();
    const currentOrg = useTypedSelector(selectedOrg);
    const isSuperRealm = useTypedSelector((state: RootState) => state.common.isSuperRealm);
    const isAdminRealm = useTypedSelector((state: RootState) => state.common.isAdminRealm);
    const headerColorChange = useSelector((state: any) => state.common.headerColor);
    const timelineStatusColor = useSelector((state: any) => state.common.timelineStatusColor);
    const { t } = useTranslation();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const NO_ORGANIZATION =
        (!currentOrg?.id && location.pathname === `/${currentRealmName}/organization`) ||
        location.pathname.indexOf('orgmanagementdetails') !== -1;
    const appHeaderSubTitle = useTypedSelector((state) => state.common.appHeaderSubTitle);
    const pageHeaderData = useTypedSelector((state) => state.common.pageHeader);
    const appHeaderTitle = useTypedSelector((state) => state.common.appHeaderTitle);
    const widgetLoader = useTypedSelector((state) => state.widgetConfiguration.isLoading);
    const widgetNavigatorStack = useTypedSelector((state) => state.widgetConfiguration.saveAsCount);

    const handleNavigateBack = (): void =>
        widgetNavigatorStack > 1 ? navigationBack(widgetNavigatorStack) : navigationBack();

    const getColors = (color: string): any => {
        switch (color) {
            case 'red':
                return Colors.red;
            case 'orange':
                return Colors.orange;
            default:
                return Colors.blue;
        }
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                }}
                minHeight={'100vh'}
                position={'relative'}
            >
                <AppBar
                    className="position-sticky"
                    sx={
                        extraProps?.noShadowedHeader
                            ? { boxShadow: 'none', color: 'inherit', pr: `0px !important` }
                            : {
                                  color: 'inherit',
                                  backgroundColor: `${timelineStatusColor || getColors(headerColorChange)[500]}`,
                                  pr: `0px !important`,
                              }
                    }
                >
                    <Toolbar
                        className={`padding-x-16 min-height-56 ${
                            isServiceAdmin
                                ? 'bg-blue-500'
                                : (currentOrg?.id ||
                                      location.pathname === `/${currentRealmName}/myProfile` ||
                                      isSuperRealm) &&
                                  !timelineStatusColor
                                ? 'bg-white'
                                : ''
                        }`}
                    >
                        {(currentOrg?.id ||
                            location.pathname === `/${currentRealmName}/myProfile` ||
                            isSuperRealm ||
                            isAdminRealm) && (
                            <IconButton onClick={handleNavigateBack}>
                                {extraProps?.crossIcon ? (
                                    <Close className="black-500" />
                                ) : (
                                    <ArrowBackIcon
                                        style={{
                                            color: timelineStatusColor ? '#FFF' : extraProps?.iconColor ?? '#424e54',
                                        }}
                                    />
                                )}
                            </IconButton>
                        )}
                        <Stack pl={3} direction={'column'}>
                            {extraProps?.noBreadcrumbs ? (
                                <Typography
                                    key={extraProps.path}
                                    variant={'h6'}
                                    color={'inherit'}
                                    className={`${
                                        currentOrg?.id ||
                                        location.pathname === `/${currentRealmName}/myProfile` ||
                                        isSuperRealm
                                            ? 'text-secondary'
                                            : 'text-white'
                                    }`}
                                >
                                    {extraProps?.path?.includes('editWidget') ? (
                                        <>
                                            {widgetLoader ? (
                                                <Skeleton
                                                    animation="wave"
                                                    variant="rounded"
                                                    width={200}
                                                    height={15}
                                                    sx={{ marginBottom: '10px', marginTop: '10px' }}
                                                />
                                            ) : (
                                                pageHeaderData?.title ?? ''
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {NO_ORGANIZATION ? (
                                                'No Organization'
                                            ) : pageHeaderData?.title ?? appHeaderTitle ? (
                                                appHeaderTitle
                                            ) : (
                                                <CustomTransComponent translationKey={extraProps?.title} />
                                            )}
                                        </>
                                    )}
                                </Typography>
                            ) : (
                                <Breadcrumbs separator={'/'}>
                                    {nestedRoutesList?.map(
                                        (route: Route): any =>
                                            route.title && (
                                                <Typography
                                                    key={`${route.path}-breadcrumb`}
                                                    variant={'h6'}
                                                    color={'inherit'}
                                                    onClick={(): void => {
                                                        navigate(`/${currentRealmName}/${route.path}`);
                                                    }}
                                                    className={
                                                        isServiceAdmin
                                                            ? 'text-white'
                                                            : (currentOrg?.id ||
                                                                  location.pathname ===
                                                                      `/${currentRealmName}/myProfile` ||
                                                                  isSuperRealm) &&
                                                              !timelineStatusColor
                                                            ? 'text-secondary'
                                                            : 'text-white'
                                                    }
                                                >
                                                    {appHeaderTitle ? (
                                                        appHeaderTitle
                                                    ) : (
                                                        <CustomTransComponent translationKey={route.title} />
                                                    )}
                                                    {/* {t(route.title)} */}
                                                </Typography>
                                            )
                                    )}
                                </Breadcrumbs>
                            )}

                            <Typography variant={'body1'} color={extraProps?.fontColor ?? Colors.black[500]}>
                                {t(title)}
                            </Typography>
                            {!extraProps?.noSubtitle && (
                                <Typography variant={'body1'} color={timelineStatusColor ? '#fff' : Colors.black[500]}>
                                    {extraProps?.subTitle ? (
                                        <CustomTransComponent translationKey={extraProps?.subTitle} />
                                    ) : appHeaderSubTitle ? (
                                        appHeaderSubTitle
                                    ) : (
                                        currentOrg?.name ?? ''
                                    )}
                                </Typography>
                            )}
                        </Stack>
                        <Spacer />
                        {NO_ORGANIZATION && <HeaderActions disabled={!currentOrg?.id} />}
                    </Toolbar>
                </AppBar>
                {page}
            </Box>
        </>
    );
};
