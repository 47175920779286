import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { api } from './api/baseApi';
import authSlice from '../features/auth/authSlice';
import commonSlice from '../features/common/commonSlice';
import realmSlice from '../features/realmManagement/realmSlice';
import orgSlice from '../features/orgManagement/orgSlice';
import groupSlice from '../features/group/groupSlice';
import widgetSlice from '../features/widgetManagement/widgetSlice';
import detailsWidgetSlice from '../features/widgetManagement/detailsWidgetSlice';
import tagSlice from '../features/TagManagement/tagManagementSlice';
import widgetConfigurationSlice from '../features/widgetManagement/widgetConfigurationSlice';
import dashboardSlice from '../features/dashboardManagement/dashboardSlice';
import automationSlice from '../features/automation/automationSlice';
import notificationSlice from '../features/notification/notificationSlice';
import gaugeWidgetSlice from '../features/widgetManagement/gaugeWidgetSlice';
import profileSlice from '../features/profile/profileSlice';
import chartWidgetSlice from '../features/widgetManagement/chartWidgetSlice';
import deviceListWidgetSlice from '../features/widgetManagement/deviceListWidgetSlice';
import commonDashboardSlice from '../features/dashboardManagement/commonDashboardSlice';
import trendsWidgetSlice from '../features/widgetManagement/trendsWidgetSlice';
import timelineWidgetSlice from '../features/widgetManagement/timelineWidgetSlice';
import realmDashboardSlice from '../features/dashboardManagement/realmDashboardSlice';
import groupListWidgetSlice from '../features/widgetManagement/groupListWidgetSlice';
import mapWidgetSlice from '../features/widgetManagement/mapWidgetSlice';
import loadsWidgetSlice from '../features/widgetManagement/loadsWidgetSlice';
import loadSlice from '../features/Loads/loadSlice';
import deviceSlice from '../features/deviceManagement/deviceSlice';
import dataGridSlice from '../features/component/dataGridSlice';
import tempSlice from '../features/widgetManagement/tempSlice';
import virtualLoadsWidgetSlice from '../features/widgetManagement/virtualLoadsWidgetSlice';
import metricsWidgetSlice from '../features/widgetManagement/metricsWidgetSlice';
import consumptionBreakdownWidgetSlice from '../features/widgetManagement/consumptionBreakdownWidgetSlice';
import loadTrendsWidgetSlice from '../features/widgetManagement/loadTrendsWidgetSlice';
import energyFlowWidgetSlice from '../features/widgetManagement/energyFlowWidgetSlice';
import timelineSlice from '../features/Timeline/timelineSlice';
import apiSlice from './api/apiSlice';
import virtualLoadSlice from '../features/deviceManagement/virtualLoadSlice';
import upcomingWidgetSlice from '../features/widgetManagement/upcomingWidgetSlice';
import configurationSlice from '../features/nfc/nfcSlice';
import weatherWidgetSlice from '../features/widgetManagement/weatherWidgetSlice';
import drawerNodeSlice from '../features/common/drawerNodeSlice';
import aggregatedTrendsWidgetSlice from '../features/widgetManagement/aggregatedTrendsWidgetSlice';
import themePaletteSlice from '../features/common/themePalette';
import historySlice from '../features/common/historySlice';
import componentWidgetSlice from '../features/widgetManagement/componentWidgetSlice';
import realmOrgsSlice from '../features/realmOrgs/realmOrgsSlice';
import scoreWidgetSlice from '../features/widgetManagement/scoreWidgetSlice';
import localizationSlice from '../features/localization/localizaitonSlice';
import dataOperationsSlice from '../features/common/dataOperationsSlice';
import scheduleEventSlice from '../features/widgetManagement/scheduleEventSlice';
import deviceTypesSlice from '../features/deviceManagement/deviceTypesSlice';
import commandBarWidgetSlice, { commandPaletteSlice } from '../features/widgetManagement/commandBarWidgetSlice';
import scheduleMobileSlice from '../features/schedule/scheduleMobileSlice';
import entitlementSlice from '../features/entitlements/entitlementSlice';
import subscriptionSlice from '../features/entitlements/subscriptions/subscriptionSlice';
import tableSlice from '../features/table/tableSlice';
import featuredChannelsSlice from '../features/deviceManagement/featuredChannelsSlice';
import scheduleSlice from '../features/component/schedule/scheduleSlice';
import schedulerSlice from '../features/schedule/schedulerSlice';
import schedulerCrudSlice from '../features/schedule/scheduleCrudSlice';
import scheduleWidgetSlice from '../features/widgetManagement/scheduleWidgetSlice';

const appReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [apiSlice.name]: apiSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [commonSlice.name]: commonSlice.reducer,
    [realmSlice.name]: realmSlice.reducer,
    [orgSlice.name]: orgSlice.reducer,
    [groupSlice.name]: groupSlice.reducer,
    [tagSlice.name]: tagSlice.reducer,
    [dashboardSlice.name]: dashboardSlice.reducer,
    [automationSlice.name]: automationSlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [profileSlice.name]: profileSlice.reducer,
    [commonDashboardSlice.name]: commonDashboardSlice.reducer,
    [realmDashboardSlice.name]: realmDashboardSlice.reducer,
    [widgetSlice.name]: widgetSlice.reducer,
    [widgetConfigurationSlice.name]: widgetConfigurationSlice.reducer,
    [loadSlice.name]: loadSlice.reducer,
    [detailsWidgetSlice.name]: detailsWidgetSlice.reducer,
    [mapWidgetSlice.name]: mapWidgetSlice.reducer,
    [gaugeWidgetSlice.name]: gaugeWidgetSlice.reducer,
    [chartWidgetSlice.name]: chartWidgetSlice.reducer,
    [deviceListWidgetSlice.name]: deviceListWidgetSlice.reducer,
    [trendsWidgetSlice.name]: trendsWidgetSlice.reducer,
    [timelineWidgetSlice.name]: timelineWidgetSlice.reducer,
    [groupListWidgetSlice.name]: groupListWidgetSlice.reducer,
    [loadsWidgetSlice.name]: loadsWidgetSlice.reducer,
    [componentWidgetSlice.name]: componentWidgetSlice.reducer,
    [scoreWidgetSlice.name]: scoreWidgetSlice.reducer,
    [energyFlowWidgetSlice.name]: energyFlowWidgetSlice.reducer,
    [aggregatedTrendsWidgetSlice.name]: aggregatedTrendsWidgetSlice.reducer,
    [deviceSlice.name]: deviceSlice.reducer,
    [dataGridSlice.name]: dataGridSlice.reducer,
    [tempSlice.name]: tempSlice.reducer,
    [virtualLoadsWidgetSlice.name]: virtualLoadsWidgetSlice.reducer,
    [metricsWidgetSlice.name]: metricsWidgetSlice.reducer,
    [consumptionBreakdownWidgetSlice.name]: consumptionBreakdownWidgetSlice.reducer,
    [loadTrendsWidgetSlice.name]: loadTrendsWidgetSlice.reducer,
    [virtualLoadSlice.name]: virtualLoadSlice.reducer,
    [timelineSlice.name]: timelineSlice.reducer,
    [upcomingWidgetSlice.name]: upcomingWidgetSlice.reducer,
    [commandBarWidgetSlice.name]: commandBarWidgetSlice.reducer,
    [commandPaletteSlice.name]: commandPaletteSlice.reducer,
    [configurationSlice.name]: configurationSlice.reducer,
    [weatherWidgetSlice.name]: weatherWidgetSlice.reducer,
    [drawerNodeSlice.name]: drawerNodeSlice.reducer,
    [themePaletteSlice.name]: themePaletteSlice.reducer,
    [historySlice.name]: historySlice.reducer,
    [realmOrgsSlice.name]: realmOrgsSlice.reducer,
    [localizationSlice.name]: localizationSlice.reducer,
    [dataOperationsSlice.name]: dataOperationsSlice.reducer,
    [deviceTypesSlice.name]: deviceTypesSlice.reducer,
    [scheduleMobileSlice.name]: scheduleMobileSlice.reducer,
    [entitlementSlice.name]: entitlementSlice.reducer,
    [tableSlice.name]: tableSlice.reducer,
    [subscriptionSlice.name]: subscriptionSlice.reducer,
    [scheduleEventSlice.name]: scheduleEventSlice.reducer,
    [featuredChannelsSlice.name]: featuredChannelsSlice.reducer, //
    [scheduleSlice.name]: scheduleSlice.reducer,
    [schedulerSlice.name]: schedulerSlice.reducer,
    [schedulerCrudSlice.name]: schedulerCrudSlice.reducer,
    [scheduleWidgetSlice.name]: scheduleWidgetSlice.reducer,
});

const rootReducer = (state: any, action: any): any => {
    if (action.type === 'RESET_STORE') {
        return appReducer(
            {
                apiSlice: state.apiSlice,
            } as any,
            action
        );
    }
    return appReducer(state, action);
};
const middlewares = [api.middleware];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const setupStore = (baseUrl: string, urlSuffix?: string, preloadedState?: any): any => {
    return configureStore({
        reducer: rootReducer,
        preloadedState: {
            ...preloadedState,
            apiSlice: {
                apiUrl: baseUrl,
                urlSuffix,
                ...(preloadedState?.apiSlice ?? {}),
            },
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
    });
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// export type AppDispatch = typeof store.dispatch;
// export type AppStore = typeof store;
export const useAppDispatch: () => any = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
