import { current } from '@reduxjs/toolkit';

function handleEnergyFlowAssetTypes(state: any, action: any): void {
    if (action.payload.type === 'setAll') {
        state['assetTypes'] = action.payload.data;
    } else {
        const stateClone = current(state);
        const selectedAssetIndex = stateClone['assetTypes'][action.payload.key]?.findIndex(
            (assetType: any) => assetType?.deviceCategory === action.payload.value
        );

        if (selectedAssetIndex !== -1) {
            state['assetTypes'][action.payload.key][selectedAssetIndex]['isEnabled'] =
                !state['assetTypes'][action.payload.key][selectedAssetIndex]['isEnabled'];
        }
    }
}
function handleAssetTypes(state: any, action: any): void {
    if (action.payload.type === 'setAll') {
        state['assetTypes'] = action.payload.data;
    } else {
        const stateClone = current(state);
        const selectedAssetIndex = stateClone['assetTypes']?.findIndex(
            (assetType: any) => assetType?.deviceCategory === action.payload.value
        );

        if (selectedAssetIndex !== -1) {
            state['assetTypes'][selectedAssetIndex]['isEnabled'] =
                !state['assetTypes'][selectedAssetIndex]['isEnabled'];
        }
    }
}

function commonWidgetConfiguration(payloadClone: any): any {
    const payload: any = { secondary: [] };
    Object.keys(payloadClone)?.forEach((key: string) => {
        if (Array.isArray(payloadClone[key]) && payloadClone[key]?.length) {
            payloadClone[key]?.forEach((item: any) => {
                const selectedIndex = payload[key]?.findIndex((previtem: any) => previtem?.deviceId === item?.deviceId);
                const selectedEntity = payload[key]?.find((previtem: any) => previtem?.deviceId === item?.deviceId);

                if (item?.deviceId !== selectedEntity?.deviceId) {
                    if (payload[key]) {
                        payload?.[key]?.push({
                            deviceId: item.deviceId,
                            deviceName: item?.deviceName,
                            channels: [item],
                        });
                    } else {
                        payload[key] = [
                            {
                                deviceId: item.deviceId,
                                deviceName: item?.deviceName,
                                channels: [item],
                            },
                        ];
                    }
                    return;
                }
                payload?.[key]?.[selectedIndex]?.channels?.push(item);
            });
            return;
        }
        payload[key] = payloadClone[key];
    });

    return payload;
}

function channelsHandler(state: any, action: any, keyToTraverse?: string): void {
    const currentData = current(state);
    const selectedChannelIndex = currentData?.[action.payload.key]?.findIndex(
        (data: any) =>
            data?.[keyToTraverse ?? 'channelId'] === action.payload?.channelPayload?.[keyToTraverse ?? 'channelId']
    );

    const selectedEntityIndex = currentData?.[action.payload.key]?.findIndex(
        (data: any) =>
            data?.deviceId === action.payload?.channelPayload?.deviceId &&
            data[keyToTraverse ?? 'channelId'] === action.payload?.channelPayload?.[keyToTraverse ?? 'channelId']
    );
    if (selectedChannelIndex !== undefined && selectedChannelIndex !== -1) {
        if (!action?.payload?.multiDevice) {
            state[action.payload.key]?.splice(selectedChannelIndex, 1);
        } else if (selectedEntityIndex !== -1) {
            state[action.payload.key]?.splice(selectedEntityIndex, 1);
        } else {
            if (state[action.payload.key]) {
                state[action.payload.key]?.push({ ...action.payload.channelPayload });
            } else {
                state[action.payload.key] = [{ ...action.payload.channelPayload }];
            }
        }
    } else {
        if (state[action.payload.key]) {
            state[action.payload.key]?.push({ ...action.payload.channelPayload });
        } else {
            state[action.payload.key] = [{ ...action.payload.channelPayload }];
        }
    }
}

function handleReallocateChannels(state: any, action: any): void {
    const movedItem = state[action.payload?.type]?.splice(action.payload?.sourceIndex, 1);
    state[action.payload?.type]?.splice(action.payload.destinationIndex, 0, ...movedItem);
}

function moveWithinList(state: any, action: any): void {
    const movedItem = state[action.payload.draggableId]?.splice(action.payload.sourceIndex, 1);
    state[action.payload.droppableId].splice(action.payload.destinationIndex, 0, ...movedItem);
}

function handleChannelRemove(state: any, action: any, keyToTraverse?: string): void {
    const currentData = current(state);
    if (currentData.widgetTypeId === 'weather') {
        if (currentData[action.payload.type]) {
            state[action.payload.type] = {};
        }
    } else {
        const selectedChannelIndex = currentData[action.payload.type]?.findIndex(
            (data: any) => data[keyToTraverse ?? 'channelId'] === action.payload?.channel[keyToTraverse ?? 'channelId']
        );
        if (selectedChannelIndex !== -1) {
            state[action.payload.type]?.splice(selectedChannelIndex, 1);
        }
    }
}

function setChannelDetails(action: any): any {
    const payloadClone = JSON.parse(JSON.stringify(action.payload.channelPayload));
    action?.payload?.details?.data?.forEach((detail: any) => {
        for (const key in payloadClone) {
            if (key === 'primary' || key === 'secondary') {
                payloadClone[key]?.map((item: any) => {
                    if (item?.deviceId === detail.deviceId) {
                        const selectedChannel = detail?.channels?.find(
                            (payloadChannel: any) => payloadChannel?.channelId === item?.channelId
                        );
                        if (selectedChannel?.channelId === item.channelId) {
                            if (selectedChannel?.records) {
                                item['data'] = selectedChannel.records;
                            } else {
                                item['value'] = selectedChannel.value;
                            }

                            item['unit'] = selectedChannel?.unit;
                        }
                    }
                    return item;
                });
            }
        }
    });
    return payloadClone;
}

function handleGroupDevicesFilter(state: any, action: any): any {
    const currentState = current(state);

    if (Array.isArray(action.payload.value)) {
        state[action.payload.key] = action.payload.value;
    } else {
        const selectedIndex = currentState[action.payload.key]?.findIndex(
            (item: any) => item.id === action.payload?.value?.id
        );
        if (selectedIndex !== -1) {
            if (currentState[action.payload.key]?.length === 1) {
                state['secondary'] = [];
            }
            state[action.payload.key].splice(selectedIndex, 1);
        } else {
            state[action.payload.key].push(action.payload.value);
        }
    }
}

export {
    commonWidgetConfiguration,
    channelsHandler,
    handleAssetTypes,
    handleEnergyFlowAssetTypes,
    setChannelDetails,
    handleChannelRemove,
    handleGroupDevicesFilter,
    handleReallocateChannels,
    moveWithinList,
};
