import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleAssetTypes, handleChannelRemove } from './helpers';

const weatherInitialState: any = {
    displayTitleBar: true,
    displayLastUpdate: true,
    displayForecastPeriod: true,
    selectedTimePeriod: 'today',
    displayViewBar: true,
    secondary: [],
    assetType: {},
    assets: {},
    lat: 0,
    lng: 0,
    widgetTypeId: 'weather',
};

const weatherWidgetSlice = createSlice({
    name: 'weather',
    initialState: weatherInitialState,
    reducers: {
        resetWeatherConfiguration: () => {
            return weatherInitialState;
        },
        setWeatherAssets: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload ?? [];
        },
        setWeatherLatitude: (state: RootState, action: PayloadAction<any>) => {
            state.lat = action.payload;
        },
        setWeatherLongitude: (state: RootState, action: PayloadAction<any>) => {
            state.lng = action.payload;
        },
        setPredefinedWeather: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                state[key] = action.payload[key];
            });
        },
        setWeatherAssetTypes: (state: RootState, action: PayloadAction<any>) => {
            handleAssetTypes(state, action);
        },
        reAllocateWeatherAssets: (state: RootState, action: PayloadAction<any>) => {
            const movedItem = state['assetTypes'][action.payload?.type]?.splice(action.payload?.sourceIndex, 1);
            state['assetTypes'][action.payload?.type]?.splice(action.payload.destinationIndex, 0, ...movedItem);
        },
        setWeatherPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        setSelectedWeatherAssets: (state, action) => {
            state.assets = action.payload;
        },
        removeAssetsForWeather: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
            state['assets'] = [];
        });
    },
});

export const {
    setWeatherAssetTypes,
    setWeatherPreferences,
    resetWeatherConfiguration,
    setSelectedWeatherAssets,
    reAllocateWeatherAssets,
    setWeatherAssets,
    setWeatherLatitude,
    setWeatherLongitude,
    setPredefinedWeather,
    removeAssetsForWeather,
} = weatherWidgetSlice.actions;

export default weatherWidgetSlice;
