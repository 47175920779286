import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { commonWidgetConfiguration, setChannelDetails } from './helpers';

const initialState = {
    data: { multiDevice: false },
    isLoading: true,
    isCustomWidgetLoaded: {},
    device: undefined,
    keyToTraverse: undefined,
    source: undefined,
    searchPayload: undefined,
    previewNodeModal: {
        isVisible: false,
    },
    saveModalType: undefined,
    node: undefined,
    saveAsCount: 1,
};

const widgetConfigurationSlice = createSlice({
    name: 'widgetConfiguration',
    initialState: initialState,
    reducers: {
        resetWidgetConfiguration: () => {
            return initialState;
        },
        setRoute: (state: RootState, action: PayloadAction<any>) => {
            state.data['route'] = action.payload;
        },
        setSelectedPreviewNode: (state: RootState, action: PayloadAction<any>) => {
            state['node'] = action.payload;
        },
        togglePreviewNodeModal: (state: RootState, action: PayloadAction<any>) => {
            state['previewNodeModal']['isVisible'] = action.payload;
        },
        setWidgetName: (state: RootState, action: PayloadAction<any>) => {
            state.data.widgetName = action.payload;
        },
        setChannelSearchPayload: (state: RootState, action: PayloadAction<any>) => {
            state['searchPayload'] = action.payload;
        },
        setWidgetLoader: (state: RootState, action: PayloadAction<any>) => {
            state.isLoading = action.payload;
        },
        setIsCustomWidgetLoaded: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload).forEach((widgetTypeId) => {
                state.isCustomWidgetLoaded[widgetTypeId] = action.payload[widgetTypeId];
            });
        },
        setWidgetDevice: (state: any, action: PayloadAction<any>) => {
            state.device = action.payload.data;
        },
        setSaveAsCount: (state: RootState, action: PayloadAction<any>) => {
            state['saveAsCount'] = action.payload;
        },
        setWidgetSource: (state: RootState, action: PayloadAction<any>) => {
            state['source'] = action.payload;
        },
        toggleDeviceLinkMode: (state: RootState) => {
            state['data']['multiDevice'] = !state['data']['multiDevice'];
            if (state['data']['multiDevice']) {
                state['source'] = undefined;
            }
        },
        setGaugeWidgetConfiguration: (state: RootState, action: PayloadAction<any>) => {
            state.data = { ...state.data, ...action.payload };
            state.isLoading = false;
        },
        setWidgetConfiguration: (state: RootState, action: PayloadAction<any>) => {
            const payloadClone = setChannelDetails(action);
            const payload = commonWidgetConfiguration(payloadClone);
            state.data = { ...state.data, ...payload };
            state.isLoading = false;
        },
        setChartConfiguration: (state: RootState, action: PayloadAction<any>) => {
            const payloadClone = setChannelDetails(action);
            payloadClone.secondary.map((item: any) => {
                if (item.value !== 'nan') {
                    item.value = parseInt(item.value);
                }
                return item;
            });
            state.data = { ...state.data, ...payloadClone };
            state.isLoading = false;
        },
        setSaveModalType: (state: RootState, action: PayloadAction<any>) => {
            state['saveModalType'] = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('load_trends/setPredefinedLoadTrends', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('consumption_breakdown/setPredefinedConsumptionBreakdown', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('metrics/setPredefinedMetrics', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('energy_flow/setPredefinedEnergyFlow', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('weather/setPredefinedWeather', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('aggregated_trends/setPredefinedAggregatedTrends', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('details/setPredefinedConfiguration', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('details/setPredefinedGauge', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('trends/setPredefinedTrends', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('loads/setPredefinedLoads', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('charts/setPredefinedChart', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('group_list/setPredefinedGroupListConfiguration', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('device_list/setPredefinedDeviceList', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('map/setPredefinedMapConfiguration', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('timeline/setPredefinedTimelineConfiguration', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('command_bar/setPredefinedCommandBarConfiguration', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
        builder.addCase('schedule_widget/setPredefinedScheduleConfiguration', (state, action: any) => {
            state.data.multiDevice = action.payload?.multiDevice;
        });
    },
});

export const {
    setRoute,
    setWidgetName,
    setSaveAsCount,
    setWidgetLoader,
    setIsCustomWidgetLoaded,
    setSelectedPreviewNode,
    setWidgetDevice,
    setWidgetSource,
    setSaveModalType,
    toggleDeviceLinkMode,
    setChartConfiguration,
    togglePreviewNodeModal,
    setWidgetConfiguration,
    setChannelSearchPayload,
    resetWidgetConfiguration,
    setGaugeWidgetConfiguration,
} = widgetConfigurationSlice.actions;

export default widgetConfigurationSlice;
