/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';
import {
    configureSummaryValues,
    setMqttTopics,
    setSummaryWidgetDetailsLoader,
} from '../features/dashboardManagement/commonDashboardSlice';

import {
    getFromToFormat,
    getMqttTopiceByWidgetType,
    getTimeInFromTo,
    isNullOrUndefined,
    systemTimezone,
    timeStampHandler,
} from '../utils/helpers';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { configureValues, setWidgetDetailsLoader } from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { selectedOrg } from '../features/orgManagement/orgSlice';
import { useGetDeviceByIdQuery } from '../features/deviceManagement/deviceApi';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
type ReturnProps = {
    payload: any;
    response: any;
};

export const useGetWidgetDetails = ({
    selectorType,
    dataGetter,
}: {
    selectorType: string;
    dataGetter: any;
}): ReturnProps => {
    const widgetsClone = useTypedSelector((state) => state[selectorType]?.widgetList);
    const selectedNode = useTypedSelector((state) => state.common.selectedNode);
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const { data: deviceData }: any = useGetDeviceByIdQuery(dataGetter['deviceId'], {
        skip: !dataGetter['deviceId'] || !dataGetter['assignType'],
    });

    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const { data: profileData }: any = useGetUserProfileQuery({}, { skip: !currentOrg?.id });

    function detailDispatcher(widgetId: any, config: any): void {
        if (selectorType === 'dashboard') {
            if (config && Object.keys(config)?.length) {
                dispatch(setWidgetDetailsLoader({ key: widgetId, value: 'loaded' }));
            }
            dispatch(configureValues({ widgetId: widgetId, config: config }));
        } else {
            dispatch(setSummaryWidgetDetailsLoader({ key: widgetId, value: 'loaded' }));
            dispatch(configureSummaryValues({ widgetId: widgetId, config: config }));
        }
    }

    function detailHelper(selectedWidget: any, response: any): void {
        response?.forEach((channel: any) => {
            const selectedEntity = selectedWidget?.config?.['secondary']?.find(
                (item: any) => item.deviceId === channel.deviceId
            );
            const selectedEntityIndex = selectedWidget?.config?.['secondary']?.findIndex(
                (item: any) => item.deviceId === channel.deviceId
            );

            channel?.channels?.forEach((subChannel: any) => {
                if (selectedWidget?.config?.multiDevice) {
                    const selectedIndex = selectedEntity?.channels?.findIndex(
                        (subItem: any) => subItem.channelId === subChannel?.channelId
                    );

                    if (selectedIndex !== -1 && selectedEntityIndex !== -1) {
                        selectedWidget.config['secondary'][selectedEntityIndex]['channels'][selectedIndex] = {
                            ...selectedWidget.config['secondary'][selectedEntityIndex]['channels'][selectedIndex],
                            value: subChannel?.value,
                            ...(subChannel?.defaultValue && { defaultValue: subChannel.defaultValue }),
                            unit: subChannel?.unit ?? '',
                        };
                    }
                    return;
                }

                const selectedIndex = selectedWidget?.config?.['secondary'][0]?.channels?.findIndex(
                    (subItem: any) => subItem.channelId === subChannel?.channelId
                );

                if (!isNullOrUndefined(selectedIndex) && selectedIndex !== -1) {
                    selectedWidget.config['secondary'][0]['channels'][selectedIndex] = {
                        ...selectedWidget.config['secondary'][0]?.['channels'][selectedIndex],
                        value: subChannel?.value,
                        ...(subChannel?.defaultValue && { defaultValue: subChannel.defaultValue }),
                        unit: subChannel?.unit ?? '',
                    };
                }
            });
        });
    }

    function detailsPayloadHelper(config: any, key: any, payload: any, extraArgs?: any): void {
        if (config) {
            config[key]?.forEach((channel: any) => {
                const selectedDeviceIndex = payload?.findIndex((device: any) => device?.deviceId === channel?.deviceId);
                if (selectedDeviceIndex !== -1) {
                    payload[selectedDeviceIndex][extraArgs ?? 'channelId'].push(
                        ...channel.channels.map((item: any) => item.channelId)
                    );
                } else {
                    payload.push({
                        deviceId: config['multiDevice'] ? channel?.deviceId : dataGetter['deviceId'],
                        [extraArgs ?? 'channelId']: channel?.channels?.map((item: any) => item.channelId),
                    });
                }
            });
        }
    }

    function getDeviceDetailsPayload(config: any): any[] {
        const payload: any = [];
        for (const key in config) {
            if (key === 'primary' || key === 'secondary') {
                detailsPayloadHelper(config, key, payload);
            }
        }
        return payload;
    }

    function getDeviceSettingsPayload(config: any, key: string, extra?: any): any[] {
        const payload: any = [];
        detailsPayloadHelper(config, key, payload, extra);
        return payload;
    }

    function getChartsDetailsPayload(config: any): any[] {
        const payload: any = [];
        config?.secondary?.forEach((channel: any) => {
            if (config?.multiDevice) {
                const selectedDeviceIndex = payload?.findIndex((device: any) => device?.deviceId === channel?.deviceId);
                if (selectedDeviceIndex !== -1) {
                    payload[selectedDeviceIndex]['channelId'].push(channel.channelId);
                } else {
                    payload.push({
                        deviceId: channel?.deviceId,
                        channelId: [channel?.channelId],
                    });
                }
            } else {
                if (payload?.length) {
                    payload[0]['channelId'].push(channel.channelId);
                } else {
                    payload.push({
                        deviceId: dataGetter['deviceId'],
                        channelId: [channel?.channelId],
                    });
                }
            }
        });
        return payload;
    }

    const handleRelativeBemsPayloadForEnergyFlow = (assetTypes: any): Array<{ [key: string]: any }> => {
        const payload: Array<{ [key: string]: any }> = [];
        const assetTypesClone = JSON.parse(JSON.stringify(assetTypes ?? {}));
        for (const key in assetTypesClone) {
            assetTypesClone[key].forEach((item: any) => {
                if (item.isEnabled) {
                    payload.push({
                        deviceCategory: item.deviceCategory,
                        assetTypeId: item.id,
                        assetTypeIds: item.assetTypeIds,
                        other: key === 'primary' ? false : true,
                    });
                }
            });
        }
        return payload;
    };

    const handleRelativeBemsPayload = (assetTypes: any): Array<{ [key: string]: any }> => {
        const payload: Array<{ [key: string]: any }> = [];
        const assetTypesClone = JSON.parse(JSON.stringify(assetTypes));

        assetTypesClone.forEach((item: any) => {
            if (item.isEnabled) {
                payload.push({
                    deviceCategory: item.deviceCategory,
                    assetTypeId: item.id,
                    other: false,
                    assetTypeIds: item.assetTypeIds,
                });
            }
        });

        return payload;
    };

    const handleLinkedBemsPayloadForEnergyFlow = (assets: any, assetTypes: any): Array<{ [key: string]: any }> => {
        const payload: Array<{ [key: string]: any }> = [];
        const assetsClone = JSON.parse(JSON.stringify(assets));
        const assetTypesClone = JSON.parse(JSON.stringify(assetTypes));
        processAssetTypesForEnergyFlow(assetTypesClone, assetsClone, payload);
        return payload;
    };

    const processAssetTypesForEnergyFlow = (
        assetTypesClone: any,
        assetsClone: any,
        payload: Array<{ [key: string]: any }>
    ): void => {
        for (const key in assetTypesClone) {
            assetTypesClone[key].forEach((item: any) => {
                processAssetsForEnergyFlow(item, key, assetsClone, payload);
            });
        }
    };

    const processAssetsForEnergyFlow = (
        item: any,
        key: string,
        assetsClone: any,
        payload: Array<{ [key: string]: any }>
    ): void => {
        if (!item.isEnabled) return;

        const selectedAssets = assetsClone.filter((asset: any) => item.assetTypeIds.includes(asset.deviceTypeId));
        if (selectedAssets.length === 0) return;

        const selectedIndex = payload.findIndex((preselectedAsset: any) =>
            item.assetTypeIds.includes(preselectedAsset.assetTypeId)
        );

        const assetIds = selectedAssets.map((subItem: any) => subItem.id);
        const assetTypeIds = Array.from(
            new Set(
                selectedAssets
                    .filter((asset: any) => item.assetTypeIds.includes(asset.deviceTypeId))
                    .map((asset: any) => asset.deviceTypeId)
            )
        );
        if (selectedIndex === -1) {
            payload.push({
                assetTypeId: item.id,
                other: key !== 'primary',
                assetIds,
                deviceCategory: item.deviceCategory,
                assetTypeIds: assetTypeIds,
            });
        } else {
            payload[selectedIndex] = {
                ...payload[selectedIndex],
                assetIds,
                deviceCategory: item.deviceCategory,
                assetTypeIds: assetTypeIds,
            };
        }
    };

    const handleLinkedBemsPayload = (assets: any, assetTypes: any): Array<{ [key: string]: any }> => {
        const payload: Array<{ [key: string]: any }> = [];
        const assetsClone = JSON.parse(JSON.stringify(assets));
        const assetTypesClone = JSON.parse(JSON.stringify(assetTypes));

        processAssetTypes(assetTypesClone, assetsClone, payload);
        return payload;
    };

    const processAssetTypes = (
        assetTypesClone: any,
        assetsClone: any,
        payload: Array<{ [key: string]: any }>
    ): void => {
        assetTypesClone.forEach((item: any) => {
            processAssets(item, assetsClone, payload);
        });
    };

    const processAssets = (item: any, assetsClone: any, payload: Array<{ [key: string]: any }>): void => {
        if (!item.isEnabled) return;

        const selectedAssets = assetsClone.filter((asset: any) => item.assetTypeIds.includes(asset.deviceTypeId));
        if (selectedAssets.length === 0) return;

        const selectedIndex = payload.findIndex((preselectedAsset: any) =>
            item.assetTypeIds.includes(preselectedAsset.assetTypeId)
        );
        const assetIds = selectedAssets.map((subItem: any) => subItem.id);
        const assetTypeIds = Array.from(
            new Set(
                selectedAssets
                    .filter((asset: any) => item.assetTypeIds.includes(asset.deviceTypeId))
                    .map((asset: any) => asset.deviceTypeId)
            )
        );

        if (selectedIndex === -1) {
            payload.push({
                assetTypeId: item.id,
                other: false,
                assetIds,
                deviceCategory: item.deviceCategory,
                assetTypeIds: assetTypeIds,
            });
        } else {
            payload[selectedIndex] = {
                ...payload[selectedIndex],
                assetIds,
                deviceCategory: item.deviceCategory,
                assetTypeIds: assetTypeIds,
            };
        }
    };

    function handleLoadsPayload(config: any): any[] {
        let payload: any = [];
        if (config?.selectedNodes?.length) {
            config?.selectedNodes?.forEach((item: any) => {
                const selectedIndex = payload?.findIndex((subItem: any) => subItem.deviceId === item.deviceId);
                const selectedItem = payload?.find((subItem: any) => subItem.deviceId === item.deviceId);
                if (selectedItem) {
                    payload?.[selectedIndex]?.loadId?.push(item.loadId);
                } else {
                    payload?.push({ deviceId: item.deviceId, loadId: [item.loadId] });
                }
            });
        } else {
            payload = [
                {
                    deviceId: dataGetter['deviceId'],
                    loadId:
                        config?.secondary &&
                        Array.isArray(config?.secondary) &&
                        config?.secondary?.every((item: any) => typeof item === 'object')
                            ? config?.secondary?.map((item: any) => item.loadId)
                            : config?.secondary ?? [],
                },
            ];
        }
        return payload;
    }
    const getEnergyFlowPayload = (selectedWidgetConfig: any, widgetType: any): any => {
        const result: any =
            widgetType !== 'energy_flow' &&
            widgetType !== 'schedule' &&
            (widgetType === 'load_trends'
                ? timeStampHandler(
                      profileData?.data?.timezone ?? systemTimezone,
                      profileData?.data?.calendarWeek,
                      selectedWidgetConfig?.selectedTimePeriod,
                      null,
                      null,
                      'load_trends'
                  )
                : timeStampHandler(
                      profileData?.data?.timezone ?? systemTimezone,
                      profileData?.data?.calendarWeek,
                      selectedWidgetConfig?.selectedTimePeriod
                  ));
        const payload: { [key: string]: any } = {
            ...(!selectedWidgetConfig?.multiDevice && {
                parentId: dataGetter['deviceId'] ?? dataGetter['groupId'],
                parentType: dataGetter['deviceId'] ? 'GATEWAY' : 'GROUP',
            }),
        };
        if (selectedWidgetConfig?.multiDevice) {
            payload['assets'] =
                widgetType === 'energy_flow'
                    ? handleLinkedBemsPayloadForEnergyFlow(
                          selectedWidgetConfig?.assets,
                          selectedWidgetConfig?.assetTypes
                      )
                    : handleLinkedBemsPayload(selectedWidgetConfig?.assets, selectedWidgetConfig?.assetTypes);
        } else {
            payload['assets'] =
                widgetType === 'energy_flow'
                    ? handleRelativeBemsPayloadForEnergyFlow(selectedWidgetConfig?.assetTypes)
                    : handleRelativeBemsPayload(selectedWidgetConfig?.assetTypes);
        }
        if (widgetType !== 'weather' && widgetType !== 'schedule') payload['widgetTypeId'] = widgetType;
        if (widgetType === 'metrics' || widgetType === 'load_trends') {
            payload['currentState'] = {
                startTime: result?.from,
                endTime: result?.to,
            };
            payload['previousState'] = {
                startTime: result?.prevFrom,
                endTime: result?.prevTo,
            };
            payload['timeFilter'] = selectedWidgetConfig?.selectedTimePeriod?.toUpperCase();
        } else if (widgetType === 'consumption_breakdown' || widgetType === 'aggregated_trends') {
            payload['startTime'] = result?.from;
            payload['endTime'] = result?.to;
            payload['timeFilter'] = selectedWidgetConfig?.selectedTimePeriod?.toUpperCase();
            if (widgetType === 'aggregated_trends') {
                payload['unit'] = selectedWidgetConfig?.unitType;
            }
        }
        return payload;
    };

    const getWeatherPayload = (selectedWidgetConfig: any): any => {
        const payload: any = {
            unit: profileData?.data?.temperatureUnit === 'F°(Fahrenheit)' ? 'F' : 'C',
            timeFilter: selectedWidgetConfig?.selectedTimePeriod?.toUpperCase(),
        };
        if (selectedWidgetConfig?.multiDevice) {
            payload['latitude'] = selectedWidgetConfig?.lat;
            payload['longitude'] = selectedWidgetConfig?.lng;
        } else {
            payload['latitude'] = selectedNode?.lat;
            payload['longitude'] = selectedNode?.lng;
        }

        return payload;
    };
    function handleTimelineCountsPayload(config: any): any {
        switch (true) {
            case dataGetter['groupId'] === currentOrg?.id && !config?.multiDevice:
                return {
                    organizationId: currentOrg.id,
                };

            case Boolean(config?.filters?.length):
                return config?.filters;

            case Boolean((!config?.filters || !config?.filters?.length) && dataGetter['deviceId']):
                return { deviceId: [dataGetter['deviceId']] };

            default:
                return { groupId: [dataGetter?.['groupId']] };
        }
    }

    const payload = useMemo(
        () => ({
            details: (selectedWidgetConfig: any) => getDeviceDetailsPayload(selectedWidgetConfig),
            properties: (selectedWidgetConfig: any) => getDeviceSettingsPayload(selectedWidgetConfig, 'secondary'),
            settings: (selectedWidgetConfig: any) => getDeviceSettingsPayload(selectedWidgetConfig, 'secondary'),
            command_bar: (selectedWidgetConfig: any) => getDeviceSettingsPayload(selectedWidgetConfig, 'secondary'),
            'command_bar-getAvailableCommands': (selectedWidgetConfig: any) =>
                getDeviceSettingsPayload(selectedWidgetConfig, 'secondary', 'channelIds'),
            device_list: (selectedWidgetConfig: any): any => {
                return {
                    page: 0,
                    size: selectedWidgetConfig?.maxCount,
                    ...(selectedWidgetConfig?.filters && { filters: { ...selectedWidgetConfig?.filters } }),
                    ...(dataGetter['deviceId'] &&
                        deviceData?.data?.wrapperDeviceType === 'Gateway' && {
                            filters: {
                                parentId: [dataGetter['deviceId']],
                                ...(selectedWidgetConfig?.filters && selectedWidgetConfig?.filters),
                            },
                        }),
                    ...(dataGetter['deviceId'] &&
                        deviceData?.data?.wrapperDeviceType !== 'Gateway' && {
                            filters: {
                                deviceIds: [dataGetter['deviceId']],
                                ...(selectedWidgetConfig?.filters && selectedWidgetConfig?.filters),
                            },
                        }),
                    ...(dataGetter['groupId'] &&
                        dataGetter['groupId'] !== currentOrg?.id && {
                            filters: {
                                groups: [dataGetter['groupId']],
                                ...(selectedWidgetConfig?.filters && selectedWidgetConfig?.filters),
                            },
                        }),
                    ...(selectedWidgetConfig?.sortPayload &&
                        Object.keys(selectedWidgetConfig?.sortPayload)?.length && {
                            sort: selectedWidgetConfig?.sortPayload,
                        }),
                };
            },
            schedule: (): any => {
                return {
                    filters: {
                        ...(dataGetter['deviceId'] && { deviceId: [dataGetter['deviceId']] }),
                        ...(dataGetter['groupId'] && { groupId: dataGetter['groupId'] }),
                        ...getFromToFormat('day', Date.now()),
                    },
                    page: 0,
                    size: 4,
                };
            },
            gauge: (selectedWidgetConfig: any) => [
                {
                    deviceId: selectedWidgetConfig?.data?.deviceId?.length
                        ? selectedWidgetConfig?.data?.deviceId
                        : dataGetter?.['deviceId'],
                    channelId: [selectedWidgetConfig?.data?.channelId],
                },
            ],
            charts: (selectedWidgetConfig: any) => getChartsDetailsPayload(selectedWidgetConfig),
            trends: (selectedWidgetConfig: any) => ({
                payload: getChartsDetailsPayload(selectedWidgetConfig),
                ...(selectedWidgetConfig &&
                    selectedWidgetConfig?.duration && {
                        from: getTimeInFromTo(selectedWidgetConfig?.duration ?? 24, 'h')?.from,
                        to: getTimeInFromTo(selectedWidgetConfig?.duration ?? 24, 'h')?.to,
                    }),
            }),
            'timeline-getDeviceAlertCount': (selectedWidgetConfig: any): any =>
                handleTimelineCountsPayload(selectedWidgetConfig),
            timeline: (selectedWidgetConfig: any): any => {
                return {
                    page: 0,
                    size: 6,
                    filters: {
                        ...(selectedWidgetConfig?.preferences && { severity: selectedWidgetConfig?.preferences }),
                        ...(selectedWidgetConfig?.filters
                            ? selectedWidgetConfig?.filters
                            : dataGetter?.['deviceId']
                            ? { deviceId: [dataGetter?.['deviceId']] }
                            : { groupId: [dataGetter?.['groupId']] }),
                    },
                };
            },
            group_list: (selectedWidgetConfig: any) => ({
                page: 0,
                size: selectedWidgetConfig?.maxCount,
                ...(selectedWidgetConfig?.filters
                    ? { ...selectedWidgetConfig?.filters }
                    : { relativeGroupId: dataGetter['groupId'] }),
                ...(selectedWidgetConfig?.sortPayload &&
                    Object.keys(selectedWidgetConfig?.sortPayload)?.length && {
                        sort: selectedWidgetConfig?.sortPayload,
                    }),
            }),
            map: (selectedWidgetConfig: any) => ({
                topLeft: {
                    lat: 85.09376317181992,
                    lng: -180,
                },
                bottomRight: {
                    lat: -85.22772698604217,
                    lng: 180,
                },
                precision: 1,
                filters: {
                    ...(selectedWidgetConfig?.filters
                        ? selectedWidgetConfig?.filters
                        : dataGetter['groupId']
                        ? { groups: [dataGetter['groupId']] }
                        : { deviceIds: [dataGetter['deviceId']] }),
                },
            }),
            loads: (selectedWidgetConfig: any) => handleLoadsPayload(selectedWidgetConfig),
            virtual_loads: () => ({
                ...(dataGetter?.['deviceId'] && { deviceId: dataGetter['deviceId'] }),
                page: 0,
                size: 50,
            }),
            upcoming: () => ({ page: 0, size: 6 }),
            components: () => ({ page: 0, size: 10 }),
            score: () => ({
                groupId: dataGetter['groupId'] ?? dataGetter['deviceId'],
                deviceId: dataGetter['groupId'],
            }),
            energy_flow: (selectedWidgetConfig: any): any => getEnergyFlowPayload(selectedWidgetConfig, 'energy_flow'),
            load_trends: (selectedWidgetConfig: any): any => getEnergyFlowPayload(selectedWidgetConfig, 'load_trends'),
            consumption_breakdown: (selectedWidgetConfig: any): any =>
                getEnergyFlowPayload(selectedWidgetConfig, 'consumption_breakdown'),
            aggregated_trends: (selectedWidgetConfig: any): any =>
                getEnergyFlowPayload(selectedWidgetConfig, 'aggregated_trends'),
            metrics: (selectedWidgetConfig: any): any => getEnergyFlowPayload(selectedWidgetConfig, 'metrics'),
            weather: (selectedWidgetConfig: any): any => getWeatherPayload(selectedWidgetConfig),
            default: () => [],
        }),
        [deviceData, dataGetter, currentOrg]
    );

    function handleDetailValues(response: any, widgetId: any, key: string, referenceWidget?: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(referenceWidget ?? widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        response?.forEach((channel: any) => {
            const selectedEntity = selectedWidget?.config?.[key]?.find(
                (item: any) => item.deviceId === channel.deviceId
            );
            const selectedEntityIndex = selectedWidget?.config?.[key]?.findIndex(
                (item: any) => item.deviceId === channel.deviceId
            );

            channel?.channels?.forEach((subChannel: any) => {
                if (selectedWidget?.config?.multiDevice) {
                    const selectedIndex = selectedEntity?.channels?.findIndex(
                        (subItem: any) => subItem.channelId === subChannel?.channelId
                    );

                    if (selectedIndex !== -1 && selectedEntityIndex !== -1) {
                        selectedWidget.config[key][selectedEntityIndex]['channels'][selectedIndex] = {
                            ...selectedWidget.config[key][selectedEntityIndex]['channels'][selectedIndex],
                            value: subChannel?.value,
                            ...(subChannel?.defaultValue && { defaultValue: subChannel.defaultValue }),
                            unit: subChannel?.unit ?? '',
                        };
                    }
                    return;
                }

                const selectedIndex = selectedWidget?.config?.[key][0]?.channels?.findIndex(
                    (subItem: any) => subItem.channelId === subChannel?.channelId
                );

                if (!isNullOrUndefined(selectedIndex) && selectedIndex !== -1) {
                    selectedWidget.config[key][0]['channels'][selectedIndex] = {
                        ...selectedWidget.config[key][0]?.['channels'][selectedIndex],
                        value: subChannel?.value,
                        ...(subChannel?.defaultValue && { defaultValue: subChannel.defaultValue }),
                        unit: subChannel?.unit ?? '',
                    };
                }
            });
        });
        if (selectedWidget?.config?.route) {
            if (selectedWidget?.config?.multiDevice) {
                switch (selectedWidget?.widgetType?.id) {
                    case 'details':
                        selectedWidget.config.route['url'] = `/${currentRealmName}/device-details`;
                        break;
                    case 'properties':
                        selectedWidget.config.route['url'] = `/${currentRealmName}/device-properties`;
                        break;
                    default:
                        selectedWidget.config.route['url'] = `/${currentRealmName}/device-settings`;
                        break;
                }
            } else {
                selectedWidget.config.route['url'] = `/${currentRealmName}/device/${dataGetter['deviceId']}`;
            }
        }

        detailDispatcher(widgetId, selectedWidget?.config);
        if (selectedWidget?.config?.primary?.length && key === 'secondary') {
            handleDetailValues(response, widgetId, 'primary', selectedWidget);
            return;
        }
    }

    function handleCommandBar(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );

        if (!selectedWidget?.config?.multiDevice && selectedWidget?.config) {
            selectedWidget.config.secondary = selectedWidget.config.secondary?.map((item: any) => {
                const itemClone = JSON.parse(JSON.stringify(item));
                itemClone.deviceId = dataGetter['deviceId'];
                return itemClone;
            });
        }
        if (selectedWidget?.config) {
            response?.forEach((singleResponse: any) => {
                if (singleResponse?.endpointName !== 'getAvailableCommands') {
                    detailHelper(selectedWidget, singleResponse?.data?.data);
                } else {
                    if (singleResponse?.data?.data?.controlResponses?.length) {
                        singleResponse?.data?.data?.controlResponses?.forEach((item: any) => {
                            let selectedDeviceIndex = selectedWidget?.config?.['secondary']?.findIndex(
                                (subItem: any) => subItem.deviceId === item.deviceId
                            );
                            if (selectedWidget?.config?.multiDevice) {
                                selectedDeviceIndex = selectedWidget?.config?.['secondary']?.findIndex(
                                    (subItem: any) => subItem.deviceId === item.deviceId
                                );
                            }
                            if (selectedDeviceIndex !== -1) {
                                selectedWidget.config.offlineAccess = item?.offline;
                                selectedWidget.config.secondary[selectedDeviceIndex]['channels'] =
                                    selectedWidget?.config?.secondary[selectedDeviceIndex]?.channels?.filter(
                                        (filterdItem: any) => item?.channelIds?.includes(filterdItem.channelId)
                                    );
                            }
                        });
                    }
                }
            });
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleDeviceList(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (response && selectedWidget?.config) {
            if (response?.records?.every((item: any) => item.type)) {
                selectedWidget.config['secondary'] =
                    response?.records?.filter((item: any) => item?.type === 'GROUP') ?? [];
            } else {
                selectedWidget.config['secondary'] = response?.records ?? [];
            }
            selectedWidget.config['total'] = response?.total;
        }
        if (selectedWidget?.widgetType?.id === 'group_list' && selectedWidget?.config?.route) {
            selectedWidget['config']['route']['url'] = `/${currentRealmName}/groupDetails/${dataGetter['groupId']}`;
        } else {
            if (selectedWidget?.config?.route) {
                if (!selectedWidget?.config?.multiDevice) {
                    selectedWidget['config']['route']['url'] = `/${currentRealmName}/group/${currentOrg?.id}`;
                } else {
                    selectedWidget['config']['route']['url'] = `/${currentRealmName}/multiDevices/${widgetId}`;
                }
            }
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }
    function handleConsumptionBreakdown(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );

        if (selectedWidget?.config) {
            selectedWidget.config['secondary'] = response?.map((item: any) => ({
                ...item,
                value: parseFloat(item.value),
            }));
            if (selectedWidget?.config?.route)
                selectedWidget['config']['route']['url'] = `/${currentRealmName}/consumptionBreakdownDetails/${
                    dataGetter['deviceId'] ?? dataGetter['groupId']
                }`;
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleMap(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget['config']['secondary'] = response
                ?.filter?.((item: any) => item.coordinates)
                ?.map((group: any) => ({
                    id: group?.deviceList[0]?.id ?? '-',
                    name: group?.deviceList[0]?.name ?? '-',
                    coordinates: group?.coordinates,
                    deviceCount: group.deviceCount,
                    deviceList: group?.deviceList,
                }));
        }

        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleGaugeDetails(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget.config.data = {
                ...selectedWidget?.config?.data,
                unit: response[0]?.channels[0]?.unit,
                value: response[0]?.channels[0]?.value !== 'nan' ? parseInt(response[0]?.channels[0]?.value) : 0,
            };
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleChartDetails(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            response?.forEach((channelData: any) => {
                channelData?.channels?.forEach((channel: any) => {
                    const selectedEntityIndex = selectedWidget?.config?.secondary?.findIndex(
                        (item: any) => channel.channelId === item.channelId
                    );
                    if (selectedWidget?.config?.multiDevice) {
                        if (selectedEntityIndex !== -1) {
                            selectedWidget['config']['secondary'][selectedEntityIndex] = {
                                ...selectedWidget['config']['secondary'][selectedEntityIndex],
                                unit: channel?.unit ?? '',
                                value: channel?.value !== 'nan' ? parseInt(channel?.value) : 0,
                            };
                        }
                    } else {
                        if (selectedEntityIndex !== -1) {
                            selectedWidget['config']['secondary'][selectedEntityIndex] = {
                                ...selectedWidget['config']['secondary'][selectedEntityIndex],
                                unit: channel?.unit ?? '',
                                value: channel?.value !== 'nan' ? parseInt(channel?.value) : 0,
                            };
                        }
                    }
                });
            });
            if (selectedWidget?.config?.route) {
                if (selectedWidget?.config?.multiDevice) {
                    if (selectedWidget?.widgetType?.id === 'charts') {
                        selectedWidget.config.route['url'] = `/${currentRealmName}/multiCharts/${widgetId}`;
                    } else {
                        selectedWidget.config.route['url'] = `/${currentRealmName}/device`;
                    }
                } else {
                    if (selectedWidget?.widgetType?.id === 'charts') {
                        selectedWidget.config.route['url'] = `/${currentRealmName}/chart/${dataGetter['deviceId']}`;
                    } else {
                        selectedWidget.config.route['url'] = `/${currentRealmName}/device/${dataGetter['deviceId']}`;
                    }
                }
            }
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleTrends(widgetId: any, response: any, configurableKey: any): void {
        let selectedWidget = JSON.parse(JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {}));
        if (configurableKey?.selectedWidget) {
            selectedWidget = JSON.parse(JSON.stringify(configurableKey?.selectedWidget));
        }

        if (selectedWidget?.config) {
            response?.forEach((channelData: any) => {
                channelData?.channels?.forEach((channel: any) => {
                    let selectedEntityIndex = selectedWidget?.config?.secondary?.findIndex(
                        (item: any) =>
                            channel.channelId === item.channelId && channelData?.deviceId === dataGetter['deviceId']
                    );

                    if (selectedWidget?.config?.multiDevice) {
                        selectedEntityIndex = selectedWidget?.config?.secondary?.findIndex(
                            (item: any) =>
                                channel.channelId === item.channelId && channelData?.deviceId === item?.deviceId
                        );
                        if (selectedEntityIndex !== -1) {
                            selectedWidget['config']['secondary'][selectedEntityIndex] = {
                                ...selectedWidget['config']['secondary'][selectedEntityIndex],
                                data: channel?.records ?? [],
                            };
                        }
                    } else {
                        if (!isNullOrUndefined(selectedEntityIndex) && selectedEntityIndex !== -1) {
                            selectedWidget['config']['secondary'][selectedEntityIndex] = {
                                ...selectedWidget['config']['secondary'][selectedEntityIndex],
                                data: channel?.records ?? [],
                                deviceId: dataGetter['deviceId'],
                            };
                        }
                    }
                });
            });
            if (configurableKey) {
                for (const key in configurableKey) {
                    if (!configurableKey?.selectedWidget) selectedWidget['config'][key] = configurableKey[key];
                }
            }
            if (selectedWidget?.config?.route) {
                if (selectedWidget?.config?.multiDevice) {
                    selectedWidget.config.route['url'] = `/${currentRealmName}/multiTrends/${widgetId}`;
                } else {
                    selectedWidget.config.route['url'] = `/${currentRealmName}/device/${dataGetter['deviceId']}`;
                }
            }
            dispatch(
                setMqttTopics(
                    `${getMqttTopiceByWidgetType(selectedWidget?.widgetType?.id)}/${
                        profileData?.data?.currentRealmId
                    }/${currentOrg?.id}/${deviceData?.data?.groupId}/${dataGetter['deviceId']}/devices`
                )
            );
        }

        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleTimelineDetails(widgetId: any, responses: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            responses.forEach((response: any) => {
                if (response.endpointName === 'getDeviceAlertCount') {
                    selectedWidget['config']['statusCount'] = {
                        alarms: response?.data?.data?.aggregations?.alarmCount ?? 0,
                        offline: response?.data?.data?.aggregations?.offlineCount ?? 0,
                        warnings: response?.data?.data?.aggregations?.warningCount ?? 0,
                        information: response?.data?.data?.aggregations?.informationalCount ?? 0,
                    };
                } else {
                    selectedWidget['config']['secondary'] = response?.data?.data?.records;
                }

                if (selectedWidget?.config?.route) {
                    if (!selectedWidget.config?.multiDevice) {
                        if (dataGetter['groupId']) {
                            selectedWidget['config']['route'][
                                'url'
                            ] = `/${currentRealmName}/group/${dataGetter['groupId']}`;
                        } else if (dataGetter['deviceId']) {
                            selectedWidget['config']['route'][
                                'url'
                            ] = `/${currentRealmName}/device/${dataGetter['deviceId']}`;
                        } else {
                            selectedWidget['config']['route']['url'] = `/${currentRealmName}/group/${currentOrg['id']}`;
                        }
                    } else {
                        selectedWidget['config']['route']['url'] = `/${currentRealmName}/multiTimeline/${widgetId}`;
                    }
                }
            });
        }

        detailDispatcher(widgetId, selectedWidget?.config);
    }

    const handleSchedule = (widgetId: any, response: any): void => {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget['config']['secondary'] = response?.records;
            if (selectedWidget?.config?.route) {
                if (!selectedWidget?.config?.multiDevice) {
                    selectedWidget['config']['route']['url'] = `/${currentRealmName}/${
                        dataGetter['deviceId'] ? 'device' : 'group'
                    }/${dataGetter['deviceId'] ?? dataGetter['groupId']}`;
                } else {
                    selectedWidget['config']['route']['url'] = `/${currentRealmName}/device`;
                }
            }
        }

        detailDispatcher(widgetId, selectedWidget?.config);
    };

    function handleDeviceLoads(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget['config']['secondary'] = response?.response
                ?.map((item: any) =>
                    item?.configurationList?.map((loadItem: any) => ({
                        ...loadItem,
                        deviceId: item?.deviceId,
                        offlineDevice: item?.offlineDevice,
                    }))
                )
                ?.flat(Infinity);

            if (selectedWidget?.config?.route) {
                selectedWidget.config.route['url'] = `/${currentRealmName}/device/${dataGetter['deviceId']}`;
            }
            selectedWidget.config['detailRoute'] = `/${currentRealmName}/loadDetails`;
        }

        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleVirtualLoads(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget['config']['secondary'] = response?.records;

            if (selectedWidget?.config?.route) {
                selectedWidget.config.route['url'] = `/${currentRealmName}/device/${dataGetter['deviceId']}`;
            }

            selectedWidget.config['detailRoute'] = `/${currentRealmName}/virtualLoadDetails/${dataGetter['deviceId']}`;
        }

        detailDispatcher(widgetId, selectedWidget?.config);
        // selectedWidget['config']['route']['url'] = `/${currentRealmName}/devicedashboard/${dataGetter['deviceId']}`;
    }

    function handleUpcoming(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            if (response) {
                selectedWidget.config['secondary'] = response?.records ?? [];
            }

            selectedWidget['config']['route']['url'] = `/${currentRealmName}/device/${dataGetter['deviceId']}`;
        }

        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleScore(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );

        if (selectedWidget?.config) {
            selectedWidget.config['data'] = response;
        }

        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleComponents(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );

        if (selectedWidget?.config) {
            selectedWidget.config['secondary'] = response;
        }

        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleLoadTrends(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget.config['secondary'] = response;
            selectedWidget.config['timeZone'] = profileData?.data?.timezone ?? systemTimezone;
            if (selectedWidget?.config?.route)
                selectedWidget['config']['route']['url'] = `/${currentRealmName}/loadTrend/${
                    dataGetter['deviceId'] ?? dataGetter['groupId']
                }`;
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleAggregatedTrends(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget.config['secondary'] = response;
            selectedWidget.config['timeZone'] = profileData?.data?.timezone ?? systemTimezone;
            if (selectedWidget?.config?.route)
                selectedWidget['config']['route']['url'] = `/${currentRealmName}/aggregatedTrends/${
                    dataGetter['deviceId'] ?? dataGetter['groupId']
                }`;
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleEnergyFlow(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget.config['secondary'] = response;
            if (selectedWidget?.config?.route)
                selectedWidget['config']['route']['url'] = `/${currentRealmName}/energyFlowDetails`;
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }

    function handleWeather(widgetId: any, response: any): void {
        const selectedWidget = JSON.parse(
            JSON.stringify(widgetsClone?.find((item: any) => item?.id === widgetId) ?? {})
        );
        if (selectedWidget?.config) {
            selectedWidget.config['secondary'] = response;
            if (selectedWidget?.config?.route)
                selectedWidget['config']['route']['url'] = `/${currentRealmName}/weatherDetails`;
        }
        detailDispatcher(widgetId, selectedWidget?.config);
    }

    const response = {
        properties: (apiResponse: any, widgetId: any): any =>
            handleDetailValues(apiResponse[0]?.data?.data, widgetId, 'secondary'),
        settings: (apiResponse: any, widgetId: any): any =>
            handleDetailValues(apiResponse[0]?.data?.data, widgetId, 'secondary'),
        details: (apiResponse: any, widgetId: any): any =>
            handleDetailValues(apiResponse[0]?.data?.data, widgetId, 'secondary'),
        command_bar: (apiResponse: any, widgetId: any): any => handleCommandBar(widgetId, apiResponse),
        device_list: (apiResponse: any, widgetId: any): any => handleDeviceList(widgetId, apiResponse[0]?.data?.data),
        group_list: (apiResponse: any, widgetId: any): any => handleDeviceList(widgetId, apiResponse[0]?.data?.data),
        gauge: (apiResponse: any, widgetId: any): any => handleGaugeDetails(widgetId, apiResponse[0]?.data?.data ?? []),
        map: (apiResponse: any, widgetId: any): any => handleMap(widgetId, apiResponse[0]?.data?.data ?? []),
        timeline: (apiResponse: any, widgetId: any): any => handleTimelineDetails(widgetId, apiResponse),
        loads: (apiResponse: any, widgetId: any): any => handleDeviceLoads(widgetId, apiResponse[0]?.data?.data ?? []),
        virtual_loads: (apiResponse: any, widgetId: any): any =>
            handleVirtualLoads(widgetId, apiResponse[0]?.data?.data ?? []),
        trends: (apiResponse: any, widgetId: any, extraArgs?: any): any =>
            handleTrends(widgetId, apiResponse[0]?.data?.data ?? [], extraArgs),
        charts: (apiResponse: any, widgetId: any): any =>
            handleChartDetails(widgetId, apiResponse[0]?.data?.data ?? []),
        upcoming: (apiResponse: any, widgetId: any): any => handleUpcoming(widgetId, apiResponse[0]?.data?.data ?? []),
        score: (apiResponse: any, widgetId: any): any => handleScore(widgetId, apiResponse[0]?.data?.data ?? []),
        components: (apiResponse: any, widgetId: any): any =>
            handleComponents(widgetId, apiResponse[0]?.data?.data ?? []),
        consumption_breakdown: (apiResponse: any, widgetId: any): any =>
            handleConsumptionBreakdown(widgetId, apiResponse[0]?.data?.data),
        load_trends: (apiResponse: any, widgetId: any): any => handleLoadTrends(widgetId, apiResponse[0]?.data?.data),
        energy_flow: (apiResponse: any, widgetId: any): any => handleEnergyFlow(widgetId, apiResponse[0]?.data?.data),
        metrics: (apiResponse: any, widgetId: any): any => handleEnergyFlow(widgetId, apiResponse[0]?.data?.data),
        aggregated_trends: (apiResponse: any, widgetId: any): any =>
            handleAggregatedTrends(widgetId, apiResponse[0]?.data?.data),
        weather: (apiResponse: any, widgetId: any): any => handleWeather(widgetId, apiResponse[0]?.data?.data),
        schedule: (apiResponse: any, widgetId: any): any => handleSchedule(widgetId, apiResponse[0]?.data?.data),
    };

    return {
        payload: dataGetter?.['groupId'] || dataGetter?.['deviceId'] ? payload : null,
        response: !isNullOrUndefined(widgetsClone) ? response : '',
    };
};
