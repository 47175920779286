import { api } from '../app/api/baseApi';

import { setMessageContent } from '../features/common/commonSlice';
import { useAppDispatch, useTypedSelector } from '../app/store';
import { configureSummaryValues, widgetList } from '../features/dashboardManagement/commonDashboardSlice';
import { setLoadModal } from '@fiji/common/src/features/Loads/loadSlice';

type ReturnProps = {
    handleDeviceCommands: (args0: any, args1: any) => void;
    handleDeviceLoads: (args0: any, args1: any) => void;
};

export const useDashboardCommonHandlers = (deviceId?: any): ReturnProps => {
    const widgets = useTypedSelector(widgetList);

    const dispatch = useAppDispatch();

    const handleDeviceCommands = (commandData: any, widgetId: any): void => {
        const selectedWidgetClone = JSON.parse(
            JSON.stringify(widgets?.find((item: any) => item?.id === widgetId)?.config ?? {})
        );
        if (selectedWidgetClone) {
            if (selectedWidgetClone?.secondary[0]?.channels) {
                Object.keys(selectedWidgetClone)?.forEach((key: string) => {
                    if (key === 'secondary') {
                        selectedWidgetClone['secondary'] = selectedWidgetClone['secondary']
                            ?.map((item: any) => item.channels)
                            ?.flat(Infinity);
                    }
                });
            }
            const selectedCommandIndex = selectedWidgetClone['secondary']?.findIndex(
                (item: any) => item?.deviceId === commandData?.deviceId && item?.channelId === commandData?.channelId
            );
            if (selectedCommandIndex !== -1) {
                selectedWidgetClone['secondary'][selectedCommandIndex] = {
                    ...selectedWidgetClone['secondary'][selectedCommandIndex],
                    isLoading: true,
                };
            }
            dispatch(configureSummaryValues({ widgetId: widgetId, config: selectedWidgetClone }));

            dispatch(
                (api as any)?.endpoints?.['sendDeviceCommand']?.initiate({
                    body: { channelId: commandData?.channelId, tagValue: commandData?.defaultValue },
                    deviceId: !selectedWidgetClone?.multiDevice ? deviceId : commandData?.deviceId,
                })
            ).then((response: any) => {
                const selectedCommandCopy = JSON.parse(JSON.stringify(selectedWidgetClone));

                if (response) {
                    if (selectedCommandIndex !== -1) {
                        delete selectedCommandCopy['secondary'][selectedCommandIndex]['isLoading'];
                    }

                    dispatch(configureSummaryValues({ widgetId: widgetId, config: selectedCommandCopy }));

                    dispatch(setMessageContent({ isOpen: true, message: 'Command executed successfully.' }));
                }
            });
        }
    };

    const handleDeviceLoads = (loadData: any, widgetId: any): void => {
        dispatch(
            setLoadModal({
                isOpen: true,
                details: loadData,
                command: loadData?.isLoadEnabled ? 'TURN_OFF' : 'TURN_ON',
                widgetId: widgetId,
            })
        );
    };
    return {
        handleDeviceCommands,
        handleDeviceLoads,
    };
};
