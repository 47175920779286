import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleEnergyFlowAssetTypes, handleChannelRemove } from './helpers';

const initialState: any = {
    secondary: [],
    assetTypes: {},
    assetsCount: 8,
    assets: [],
    isTitleDisplayed: true,
    isViewAllDisplayed: true,
    isAssetsTypeDisplayed: true,
    widgetTypeId: 'energy_flow',
};

const energyFlowWidgetSlice = createSlice({
    name: 'energy_flow',
    initialState,
    reducers: {
        resetEnergyFlowWidget: () => {
            return initialState;
        },
        setEnergyApiPayload: (state: RootState, action: PayloadAction<any>) => {
            state['apiPayload'] = action.payload;
        },
        setSelectedAssets: (state, action) => {
            const { id } = action.payload;
            const existingId = state['assets'].findIndex((el: any) => el.id === id);
            if (existingId !== -1) {
                state['assets'].splice(existingId, 1);
            } else {
                state['assets'].push(action.payload);
            }
        },
        setPredefinedEnergyFlow: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'assetTypes') {
                    state[key] = action.payload[key];
                }
            });
        },
        setEnergyAssetTypes: (state: RootState, action: PayloadAction<any>) => {
            handleEnergyFlowAssetTypes(state, action);
        },
        reAllocateAsset: (state: RootState, action: PayloadAction<any>) => {
            const movedItem = state['assetTypes'][action.payload?.type]?.splice(action.payload?.sourceIndex, 1);
            state['assetTypes'][action.payload?.type]?.splice(action.payload.destinationIndex, 0, ...movedItem);
        },
        moveBetweenAssetLists: (state: RootState, action: PayloadAction<any>) => {
            const movedItem = state['assetTypes'][action.payload.draggableId]?.splice(action.payload.sourceIndex, 1);
            state['assetTypes'][action.payload.droppableId].splice(action.payload.destinationIndex, 0, ...movedItem);
        },

        removeSelectedAsset: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },

        setPreviewWidgetAssetsData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload ?? [];
        },
        setEnergyDisplayPreferences: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        setAssetsCount: (state, action) => {
            const change = action.payload;
            state.assetsCount += change;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
            state['assets'] = [];
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                state.secondary = [];
                state['assets'] = [];
            }
        });
    },
});

export const {
    setAssetsCount,
    reAllocateAsset,
    setSelectedAssets,
    setEnergyApiPayload,
    removeSelectedAsset,
    setEnergyAssetTypes,
    resetEnergyFlowWidget,
    moveBetweenAssetLists,
    setPredefinedEnergyFlow,
    setPreviewWidgetAssetsData,
    setEnergyDisplayPreferences,
} = energyFlowWidgetSlice.actions;
export default energyFlowWidgetSlice;
