import React from 'react';
import { CorporateFare } from '@mui/icons-material';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { findAnglesOfTriangle } from 'utils/helpers';

const EnergyFlow = (props: any): JSX.Element => {
    const gridRef = React.useRef<HTMLDivElement | null>(null);
    const [gridMeasuresState, setGridMeasuresState] = React.useState<any>({});
    const resizeComponent = (): void => {
        const gridMeasures = gridRef.current?.getBoundingClientRect();
        if (gridMeasures) {
            setGridMeasuresState(findAnglesOfTriangle(gridMeasures.height / 3, gridMeasures.width / 3));
        }
    };
    React.useEffect(() => {
        if (gridRef?.current) {
            resizeComponent();
            new ResizeObserver(resizeComponent).observe(gridRef.current);
        }
    }, [props]);

    const GRID_DATA_MAPPING: any = {
        '1': [2],
        '2': [2, 7],
        '3': [2, 6, 8],
        '4': [1, 3, 6, 8],
        '5': [1, 3, 4, 6, 8],
        '6': [1, 3, 4, 5, 6, 8],
        '7': [1, 2, 3, 4, 5, 6, 8],
        '8': [1, 2, 3, 4, 5, 6, 7, 8],
    };

    const DATA = [
        {
            id: 1,
            icon: '?',
            label: 'Asset Name',
            value: '--',
            unit: 'MW',
            currentDirection: 'incoming', //incoming, outgoing
        },
        {
            id: 2,
            icon: '?',
            label: 'Asset Name',
            value: '--',
            unit: 'MW',
            currentDirection: 'incoming', //incoming, outgoing
        },
        {
            id: 3,
            icon: '?',
            label: 'Asset Name',
            value: '--',
            unit: 'MW',
            currentDirection: 'incoming', //incoming, outgoing
        },
        {
            id: 4,
            icon: '?',
            label: 'Asset Name',
            value: '--',
            unit: 'MW',
            currentDirection: 'incoming', //incoming, outgoing
        },
        {
            id: 5,
            icon: '?',
            label: 'Asset Name',
            value: '--',
            unit: 'MW',
            currentDirection: 'incoming', //incoming, outgoing
        },
        {
            id: 6,
            icon: '?',
            label: 'Asset Name',
            value: '--',
            unit: 'MW',
            currentDirection: 'incoming', //incoming, outgoing
        },
        {
            id: 7,
            icon: '?',
            label: 'Asset Name',
            value: '--',
            unit: 'MW',
            currentDirection: 'outgoing', //incoming, outgoing
        },
        {
            id: 8,
            icon: '?',
            label: 'Asset Name',
            value: '--',
            unit: 'MW',
            currentDirection: 'incoming', //incoming, outgoing
        },
    ];

    const STYLES_MAPPING = [
        {
            sx: { left: 0 },
            className: 'widget-top',
            direction: 'row',
            childJustifyContent: 'flex-end',
            lineSx: { transform: `skew(0deg, ${Math.ceil(gridMeasuresState.alpha)}deg)`, transformOrigin: 'left' },
        },
        {
            sx: { left: 0 },
            className: 'widget-center',
            direction: 'row',
            childJustifyContent: 'flex-end',
            lineSx: {
                width: `${(gridMeasuresState.base * 3) / 2}px`,
                borderTop: '2px solid #727E84',
                left: '75%',
            },
        },
        {
            sx: { left: 0 },
            className: 'widget-bottom',
            direction: 'row',
            childJustifyContent: 'flex-end',
            lineSx: { transform: `skew(0deg, ${Math.ceil(-gridMeasuresState.alpha)}deg)`, transformOrigin: 'left' },
        },
        {
            sx: { left: '33.33%' },
            className: 'widget-top',
            direction: 'column',
            justifyContent: 'center',
            lineSx: {
                top: `${gridMeasuresState.perpendicular * 0.8}px`,
                height: `${gridMeasuresState.perpendicular / 2}px`,
                borderLeft: '2px solid #727E84',
            },
        },
        {
            sx: { left: '33.33%' },
            className: 'widget-bottom',
            direction: 'column',
            justifyContent: 'center',
            lineSx: {
                bottom: `${gridMeasuresState.perpendicular * 0.8}px`,
                height: `${gridMeasuresState.perpendicular / 2}px`,
                borderLeft: '2px solid #727E84',
            },
        },
        {
            sx: { left: '66.66%' },
            className: 'widget-top',
            direction: 'row-reverse',
            childJustifyContent: 'flex-start',
            lineSx: { transform: `skew(0deg, ${Math.ceil(-gridMeasuresState.alpha)}deg)`, transformOrigin: 'right' },
        },
        {
            sx: { left: '66.66%' },
            className: 'widget-center',
            direction: 'row-reverse',
            childJustifyContent: 'flex-start',
            lineSx: {
                width: `${(gridMeasuresState.base * 3) / 2}px`,
                borderTop: '2px solid #727E84',
                right: '75%',
            },
        },
        {
            sx: { left: '66.66%' },
            className: 'widget-bottom',
            direction: 'row-reverse',
            childJustifyContent: 'flex-start',
            lineSx: { transform: `skew(0deg, ${Math.ceil(gridMeasuresState.alpha)}deg)`, transformOrigin: 'right' },
        },
    ];

    return (
        <Stack className="energy-flow-wrapper" direction={'row'} ref={gridRef}>
            {DATA.map((item, index) => {
                const itemStyleId = GRID_DATA_MAPPING[DATA.length.toString()][index];
                const itemStyle: any = STYLES_MAPPING[itemStyleId - 1];
                return (
                    <Stack
                        key={item.id}
                        direction={itemStyle.direction}
                        alignItems={'center'}
                        justifyContent={itemStyle.justifyContent ?? 'space-between'}
                        className={itemStyle.className}
                        sx={itemStyle.sx}
                    >
                        <Stack
                            className="flex-70"
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={itemStyle.childJustifyContent}
                        >
                            <Stack alignItems={'center'} justifyContent={'center'}>
                                <Avatar className="bg-content-color text-white height-32 width-32">{item.icon}</Avatar>
                                <Typography variant="caption" fontSize={'12px'}>
                                    {item.label}
                                </Typography>
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                        {item.value}
                                    </Typography>
                                    <Typography fontWeight={'300'} fontSize={'14px'}>
                                        {item.unit}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <React.Fragment>
                            {itemStyle.lineSx?.transformOrigin && <Box className="w-25 border-top-content-1" />}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    borderTop: '3px solid #727E84',
                                    ...(itemStyle.lineSx?.transformOrigin
                                        ? {
                                              [itemStyle.lineSx?.transformOrigin]: `${
                                                  (gridMeasuresState.base ?? 0) * 2
                                              }px`,
                                              width: `${gridMeasuresState.base - 19}px`,
                                              top: `${gridMeasuresState.perpendicular / 2}px`,
                                          }
                                        : {}),
                                    ...itemStyle.lineSx,
                                }}
                            />
                        </React.Fragment>
                    </Stack>
                );
            })}
            <Stack
                className="organization-wrapper"
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ left: '33.33%' }}
            >
                <Stack className="organization bg-gray-50">
                    <Stack alignItems={'center'} justifyContent={'center'} className="center">
                        <CorporateFare className="height-28 width-28 gray-500" />
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography fontSize={'12px'} fontWeight={'600'}>
                                --
                            </Typography>
                            <Typography fontSize={'12px'} fontWeight={'300'}>
                                MW
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EnergyFlow;
