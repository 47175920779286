import React from 'react';

import {
    setWidgetLoader,
    setGaugeWidgetConfiguration,
} from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import {
    useLazyGetLoadTrendsDataQuery,
    useLazyGetMetricsWidgetDataQuery,
    useLazyGetEnergyFLowAssetsDataQuery,
    useLazyGetConsumptionBreakdownDataQuery,
    useLazyGetAggregatedLineChartDataQuery,
    useLazyGetAggregatedBarChartDataQuery,
    useLazyGetWeatherDataQuery,
    useLazyGetAllSchedulesDataQuery,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { shallowEqual } from 'react-redux';
import { setMetricsData } from '@fiji/common/src/features/widgetManagement/metricsWidgetSlice';
import {
    setLoadTrendData,
    setLoadTrendsPreferences,
} from '@fiji/common/src/features/widgetManagement/loadTrendsWidgetSlice';
import { setBreakdownAssets } from '@fiji/common/src/features/widgetManagement/consumptionBreakdownWidgetSlice';
import { setPreviewWidgetAssetsData } from '@fiji/common/src/features/widgetManagement/energyFlowWidgetSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { systemTimezone, timeStampHandler } from '@fiji/common/src/utils/helpers';
import {
    setAggregatedTrendsData,
    setAggregatedTrendsPreferences,
} from '@fiji/common/src/features/widgetManagement/aggregatedTrendsWidgetSlice';
import {
    setSelectedWeatherAssets,
    setWeatherAssets,
    setWeatherLatitude,
    setWeatherLongitude,
} from '@fiji/common/src/features/widgetManagement/weatherWidgetSlice';
import { setScheduleData } from '@fiji/common/src/features/widgetManagement/scheduleWidgetSlice';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { UserProfile } from '@fiji/common/src/types';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';

type Args = {
    widgetType: string;
};

type ReturnProps = {
    isLoading: boolean;
};

export const useHandleBemsPayload = ({ widgetType }: Args): ReturnProps => {
    const dispatch = useAppDispatch();
    const isInitialRender = React.useRef(true); // Ref to track initial render
    const { assetTypes, assets } = useTypedSelector((state) => state[widgetType], shallowEqual);
    const [previousAssetsLength, setPreviousAssetsLength] = React.useState<number | null>(null);
    const relativeWidgetPayload = useTypedSelector((state) => state[widgetType]);
    const configurationPayload = useTypedSelector((state) => state['widgetConfiguration']);

    const [getEnergyFlowAssetData, { isLoading, isFetching, isError }] = useLazyGetEnergyFLowAssetsDataQuery();
    const [
        getConsumptionBreakdownData,
        { isLoading: consumptionLoader, isFetching: consumptionFetcher, isError: consumptionError },
    ] = useLazyGetConsumptionBreakdownDataQuery();
    const [
        getLoadTrendsData,
        { isLoading: loadTrendsLoader, isFetching: loadTrendsFetcher, isError: loadTrendsError },
    ] = useLazyGetLoadTrendsDataQuery();
    const [getMetricsData, { isLoading: metricsLoader, isFetching: metricsFetcher, isError: metricsError }] =
        useLazyGetMetricsWidgetDataQuery();
    const [getWeatherData, { isLoading: weatherLoader, isFetching: weatherFetcher, isError: weatherError }] =
        useLazyGetWeatherDataQuery();

    const [
        getAggregatedLineChartData,
        { isLoading: aggregatedLineLoader, isFetching: aggregatedLineFetcher, isError: aggregatedLineError },
    ] = useLazyGetAggregatedLineChartDataQuery();
    const [
        getAggregatedBarChartData,
        { isLoading: aggregatedBarLoader, isFetching: aggregatedBarFetcher, isError: aggregatedBarError },
    ] = useLazyGetAggregatedBarChartDataQuery();

    const [getAllSchedulesData, { isLoading: scheduleLoader, isFetching: scheduleFetcher, isError: scheduleError }] =
        useLazyGetAllSchedulesDataQuery();

    const apiCall: { [key: string]: any } = {
        energy_flow: (args: any): any => getEnergyFlowAssetData(args),
        consumption_breakdown: (args: any): any => getConsumptionBreakdownData(args),
        load_trends: (args: any): any => getLoadTrendsData(args),
        metrics: (args: any): any => getMetricsData(args),
        aggregated_trends: (args: any): any =>
            relativeWidgetPayload?.type === 'bar' ? getAggregatedBarChartData(args) : getAggregatedLineChartData(args),
        weather: (args: any): any => getWeatherData(args),
        schedule: (args: any): any => getAllSchedulesData(args),
    };

    const { data: profileDetails } = useGetUserProfileQuery({}) as ApiEndpointType<ApiResponseType<UserProfile>>;

    const [getSelectedGroup, { data: groupsData }]: any = useGetAllGroupsMutation();

    React.useEffect(() => {
        if (groupsData?.data && configurationPayload?.data?.multiDevice) {
            const assetsData = groupsData?.data?.records?.find((item: any) => item?.id === assets?.id) || {}; // Find the record that matches the asset id
            dispatch(setSelectedWeatherAssets(assetsData));
        }
    }, [groupsData?.data]);

    const handleWidgetConfig = (data: any): void => {
        switch (widgetType) {
            case 'energy_flow':
                dispatch(setPreviewWidgetAssetsData(data));
                dispatch(setWidgetLoader(false));
                break;
            case 'consumption_breakdown':
                dispatch(setBreakdownAssets(data));
                dispatch(setWidgetLoader(false));
                break;
            case 'load_trends':
                dispatch(setLoadTrendData(data));
                dispatch(
                    setLoadTrendsPreferences({
                        key: 'timeZone',
                        value: profileDetails?.data?.timezone ?? systemTimezone,
                    })
                );
                dispatch(setWidgetLoader(false));
                break;
            case 'metrics':
                dispatch(setMetricsData(data));
                dispatch(setWidgetLoader(false));
                break;
            case 'aggregated_trends':
                dispatch(setAggregatedTrendsData(data));
                dispatch(
                    setAggregatedTrendsPreferences({
                        key: 'dateFormat',
                        value: profileDetails?.data?.dateFormat ?? 'MM/DD/YYYY',
                    })
                );
                dispatch(
                    setAggregatedTrendsPreferences({
                        key: 'timeZone',
                        value: profileDetails?.data?.timezone ?? systemTimezone,
                    })
                );

                dispatch(setWidgetLoader(false));
                break;
            case 'weather':
                dispatch(setWeatherAssets(data));
                dispatch(setWidgetLoader(false));
                break;
            case 'schedule':
                dispatch(setScheduleData(data));
                dispatch(setWidgetLoader(false));
                break;
            default:
                break;
        }
    };

    const handleRelativePayloadForEnergyFlow = (): Array<{ [key: string]: any }> => {
        const payload: Array<{ [key: string]: any }> = [];
        const assetTypesClone = JSON.parse(JSON.stringify(assetTypes));
        for (const key in assetTypesClone) {
            assetTypesClone[key].forEach((item: any) => {
                if (item.isEnabled) {
                    payload.push({
                        deviceCategory: item.deviceCategory,
                        assetTypeIds: item.assetTypeIds,
                        assetTypeId: item.id,
                        other: key !== 'primary',
                    });
                }
            });
        }
        return payload;
    };

    const handleRelativePayload = (): Array<{ [key: string]: any }> => {
        const payload: Array<{ [key: string]: any }> = [];

        assetTypes.forEach((item: any) => {
            if (item.isEnabled) {
                payload.push({
                    deviceCategory: item.deviceCategory,
                    assetTypeId: item.id,
                    other: false,
                    assetTypeIds: item.assetTypeIds,
                });
            }
        });

        return payload;
    };

    const handleLinkedPayloadForEnergyFlow = (): Array<{ [key: string]: any }> => {
        const payload: Array<{ [key: string]: any }> = [];
        const assetsClone = JSON.parse(JSON.stringify(assets));
        const assetTypesClone = JSON.parse(JSON.stringify(assetTypes));

        processAssetTypesForEnergyFlow(assetTypesClone, assetsClone, payload);
        return payload;
    };

    const handleLinkedPayload = (): Array<{ [key: string]: any }> => {
        const payload: Array<{ [key: string]: any }> = [];
        const assetsClone = JSON.parse(JSON.stringify(assets));
        const assetTypesClone = JSON.parse(JSON.stringify(assetTypes));

        processAssetTypes(assetTypesClone, assetsClone, payload);
        return payload;
    };

    const processAssetTypesForEnergyFlow = (
        assetTypesClone: any,
        assetsClone: any,
        payload: Array<{ [key: string]: any }>
    ): void => {
        for (const key in assetTypesClone) {
            assetTypesClone[key].forEach((item: any) => {
                processAssetsForEnergyFlow(item, key, assetsClone, payload);
            });
        }
    };

    const processAssetTypes = (
        assetTypesClone: any,
        assetsClone: any,
        payload: Array<{ [key: string]: any }>
    ): void => {
        assetTypesClone.forEach((item: any) => {
            processAssets(item, assetsClone, payload);
        });
    };

    const processAssetsForEnergyFlow = (
        item: any,
        key: string,
        assetsClone: any,
        payload: Array<{ [key: string]: any }>
    ): void => {
        if (!item.isEnabled) return;

        const selectedAssets = assetsClone.filter((asset: any) => item.assetTypeIds.includes(asset.deviceTypeId));
        if (selectedAssets.length === 0) return;

        const selectedIndex = payload.findIndex((preselectedAsset: any) =>
            item.assetTypeIds.includes(preselectedAsset.assetTypeId)
        );

        const assetIds = selectedAssets.map((subItem: any) => subItem.id);
        const assetTypeIds = [
            ...new Set(
                selectedAssets
                    .filter((asset: any) => item.assetTypeIds.includes(asset.deviceTypeId))
                    .map((asset: any) => asset.deviceTypeId)
            ),
        ];
        if (selectedIndex === -1) {
            payload.push({
                assetTypeId: item.id,
                other: key !== 'primary',
                assetIds,
                deviceCategory: item.deviceCategory,
                assetTypeIds: assetTypeIds,
            });
        } else {
            payload[selectedIndex] = {
                ...payload[selectedIndex],
                assetIds,
                deviceCategory: item.deviceCategory,
                assetTypeIds: assetTypeIds,
            };
        }
    };

    const processAssets = (item: any, assetsClone: any, payload: Array<{ [key: string]: any }>): void => {
        if (!item.isEnabled) return;

        const selectedAssets = assetsClone.filter((asset: any) => item.assetTypeIds.includes(asset.deviceTypeId));
        if (selectedAssets.length === 0) return;

        const selectedIndex = payload.findIndex((preselectedAsset: any) =>
            item.assetTypeIds.includes(preselectedAsset.assetTypeId)
        );
        const assetIds = selectedAssets.map((subItem: any) => subItem.id);
        const assetTypeIds = [
            ...new Set(
                selectedAssets
                    .filter((asset: any) => item.assetTypeIds.includes(asset.deviceTypeId))
                    .map((asset: any) => asset.deviceTypeId)
            ),
        ];

        if (selectedIndex === -1) {
            payload.push({
                assetTypeId: item.id,
                other: false,
                assetIds,
                deviceCategory: item.deviceCategory,
                assetTypeIds: assetTypeIds,
            });
        } else {
            payload[selectedIndex] = {
                ...payload[selectedIndex],
                assetIds,
                deviceCategory: item.deviceCategory,
                assetTypeIds: assetTypeIds,
            };
        }
    };

    const handleWeatherPayload = (): any => {
        const payload: any = {
            unit: profileDetails?.data?.temperatureUnit === 'F°(Fahrenheit)' ? 'F' : 'C',
            timeFilter: relativeWidgetPayload?.selectedTimePeriod?.toUpperCase(),
        };
        if (configurationPayload?.data?.multiDevice) {
            payload['latitude'] = assets?.lat;
            payload['longitude'] = assets?.lng;
            dispatch(setWeatherLatitude(assets?.lat));
            dispatch(setWeatherLongitude(assets?.lng));
        } else {
            payload['latitude'] = configurationPayload?.device?.lat;
            payload['longitude'] = configurationPayload?.device?.lng;
            dispatch(setWeatherLatitude(configurationPayload?.device?.lat));
            dispatch(setWeatherLongitude(configurationPayload?.device?.lng));
        }
        return payload;
    };

    const handleLinkedAPICall = (): boolean => {
        const isMultiDevice = configurationPayload?.data?.multiDevice;

        const getAssetsLength = (assetsList: any): number => {
            if (Array.isArray(assetsList)) {
                return assetsList.length;
            } else if (typeof assetsList === 'object' && assetsList !== null) {
                return Object.keys(assetsList).length;
            }
            return 0; // Default to 0 if assets is neither an array nor an object
        };

        const assetsLength = getAssetsLength(assets);

        if (!isMultiDevice) {
            return true;
        }

        if (isMultiDevice) {
            if (previousAssetsLength === null && assetsLength === 1) {
                setPreviousAssetsLength(assetsLength);
                return true;
            }

            if (previousAssetsLength === 1 && assetsLength === 0) {
                setPreviousAssetsLength(assetsLength);
                return true;
            }

            setPreviousAssetsLength(assetsLength);
            return assetsLength > 0;
        }

        return false;
    };

    async function fetchData(): Promise<void> {
        const result: any =
            widgetType !== 'energy_flow' &&
            widgetType !== 'schedule' &&
            (widgetType === 'load_trends'
                ? timeStampHandler(
                      profileDetails?.data?.timezone ?? systemTimezone,
                      profileDetails?.data?.calendarWeek,
                      relativeWidgetPayload?.selectedTimePeriod,
                      null,
                      null,
                      'load_trends'
                  )
                : timeStampHandler(
                      profileDetails?.data?.timezone ?? systemTimezone,
                      profileDetails?.data?.calendarWeek,
                      relativeWidgetPayload?.selectedTimePeriod
                  ));
        const payload: { [key: string]: any } = {
            ...(!configurationPayload?.data?.multiDevice && {
                parentId: configurationPayload?.device?.id,
                parentType:
                    configurationPayload?.device?.deviceProfileId || configurationPayload?.device?.deviceTypeId
                        ? 'GATEWAY'
                        : 'GROUP',
            }),
        };

        // Check if it's the initial render and skip API call if so until assets are empty
        if (isInitialRender.current && widgetType === 'weather') {
            if (assets?.id) getSelectedGroup({ parent: assets?.parentId });
            isInitialRender.current = false;
            return;
        }

        if (widgetType !== 'weather' && widgetType !== 'schedule') payload['widgetTypeId'] = widgetType;

        const weatherPayload: any = widgetType === 'weather' && handleWeatherPayload();
        if (widgetType !== 'weather' && widgetType === 'energy_flow') {
            if (configurationPayload?.data?.multiDevice) {
                payload['assets'] = handleLinkedPayloadForEnergyFlow();
            } else {
                payload['assets'] = handleRelativePayloadForEnergyFlow();
            }
        } else if (widgetType !== 'weather' && widgetType !== 'energy_flow') {
            if (configurationPayload?.data?.multiDevice) {
                payload['assets'] = handleLinkedPayload();
            } else {
                payload['assets'] = handleRelativePayload();
            }
        }

        if (widgetType === 'aggregated_trends') {
            payload['unit'] = relativeWidgetPayload?.unitType;
        }

        if (widgetType === 'metrics' || widgetType === 'load_trends') {
            payload['currentState'] = {
                startTime: result?.from,
                endTime: result?.to,
            };
            payload['previousState'] = {
                startTime: result?.prevFrom,
                endTime: result?.prevTo,
            };
            payload['timeFilter'] = relativeWidgetPayload?.selectedTimePeriod?.toUpperCase();
        } else if (widgetType === 'consumption_breakdown' || widgetType === 'aggregated_trends') {
            payload['startTime'] = result?.from;
            payload['endTime'] = result?.to;
            payload['timeFilter'] = relativeWidgetPayload?.selectedTimePeriod?.toUpperCase();
        }

        const { error, data }: any =
            handleLinkedAPICall() && (await apiCall[widgetType](widgetType === 'weather' ? weatherPayload : payload));
        if (!error) {
            handleWidgetConfig(data?.data);
        }
    }

    React.useEffect(() => {
        if (relativeWidgetPayload?.secondary) {
            const relativeWidgetPayloadClone = JSON.parse(JSON.stringify(relativeWidgetPayload));
            delete relativeWidgetPayloadClone['multiDevice'];
            dispatch(setGaugeWidgetConfiguration(relativeWidgetPayloadClone));
        }
    }, [relativeWidgetPayload]);

    React.useEffect(() => {
        if (
            isError ||
            consumptionError ||
            loadTrendsError ||
            metricsError ||
            aggregatedBarError ||
            aggregatedLineError ||
            scheduleError
        ) {
            dispatch(setWidgetLoader(false));
            if (assets?.length === 0) {
                dispatch(setMetricsData([]));
                dispatch(setLoadTrendData([]));
                dispatch(setBreakdownAssets([]));
                dispatch(setPreviewWidgetAssetsData([]));
                dispatch(setAggregatedTrendsData({}));
            }
        } else if (weatherError) {
            dispatch(setWidgetLoader(false));
            dispatch(setWeatherAssets([]));
        }
    }, [
        isError,
        consumptionError,
        loadTrendsError,
        metricsError,
        weatherError,
        aggregatedLineError,
        aggregatedBarError,
        scheduleError,
    ]);

    React.useEffect(() => {
        if (
            isLoading ||
            isFetching ||
            consumptionLoader ||
            metricsFetcher ||
            metricsLoader ||
            consumptionFetcher ||
            loadTrendsFetcher ||
            loadTrendsLoader ||
            weatherLoader ||
            weatherFetcher ||
            aggregatedBarFetcher ||
            aggregatedBarLoader ||
            aggregatedLineFetcher ||
            aggregatedLineLoader ||
            scheduleLoader ||
            scheduleFetcher
        ) {
            dispatch(setWidgetLoader(true));
        }
    }, [
        isLoading,
        isFetching,
        consumptionFetcher,
        consumptionLoader,
        metricsLoader,
        metricsFetcher,
        loadTrendsFetcher,
        loadTrendsLoader,
        weatherLoader,
        weatherFetcher,
        aggregatedBarFetcher,
        aggregatedBarLoader,
        aggregatedLineFetcher,
        aggregatedLineLoader,
        scheduleLoader,
        scheduleFetcher,
    ]);

    React.useEffect(() => {
        if (widgetType !== 'weather' && Object.keys(assetTypes)?.length) {
            void fetchData();
        }
    }, [
        assetTypes,
        assets,
        relativeWidgetPayload?.selectedTimePeriod,
        relativeWidgetPayload?.unitType,
        relativeWidgetPayload?.type,
    ]);

    React.useEffect(() => {
        // Handle multiDevice and singleDevice cases
        const isMultiDevice = configurationPayload?.data?.multiDevice;

        // In multiDevice case, ensure assets are present before API call
        // In singleDevice case, allow the API call even if assets are not present
        if (
            widgetType === 'weather' &&
            configurationPayload?.device &&
            (isMultiDevice ? assets?.id || (isMultiDevice && !isInitialRender?.current) : true)
        ) {
            void fetchData();
        }
    }, [configurationPayload?.device, assets, relativeWidgetPayload?.selectedTimePeriod]);

    return {
        isLoading:
            isLoading ||
            isFetching ||
            consumptionFetcher ||
            consumptionLoader ||
            loadTrendsFetcher ||
            loadTrendsLoader ||
            metricsFetcher ||
            metricsLoader ||
            weatherLoader ||
            weatherFetcher ||
            aggregatedBarFetcher ||
            aggregatedBarLoader ||
            aggregatedLineFetcher ||
            aggregatedLineLoader ||
            scheduleLoader ||
            scheduleFetcher,
    };
};
