import NavigateNext from '@mui/icons-material/NavigateNext';
import { CardActions, Divider, IconButton, Stack, Typography } from '@mui/material';

import React from 'react';
import { useNavigate } from 'react-router-dom';

export const WidgetFooter = ({
    mode,
    ids,
    widgetData,
    disabled,
    modUrl,
    actionBtnName,
    noAction,
}: {
    mode: string | undefined;
    ids?: any;
    widgetData: any;
    disabled: boolean;
    modUrl?: any;
    actionBtnName?: string;
    noAction?: boolean;
}): JSX.Element => {
    const navigate = useNavigate();

    return (
        <CardActions
            sx={{
                height: '48px',
                maxWidth: mode === 'edit' ? 'calc(100% - 45px)' : '100%',
                position: mode !== 'configured' ? 'absolute' : 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                bottom: 0,
                flexDirection: 'column',
                backgroundColor: '#fff',
                ...(!noAction && {
                    cursor: mode !== 'view' ? 'auto' : 'pointer',
                }),
                zIndex:
                    widgetData?.widgetTypeId === 'energy_flow' || widgetData?.widgetTypeId === 'aggregated_trends'
                        ? 9
                        : '',
            }}
            className="bg-white"
        >
            <Divider sx={{ width: '100%', position: 'absolute', bottom: '48px' }} />
            <Stack
                py={'10px'}
                pl={'16px'}
                className="bg-white"
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
                {...(mode !== 'preview' && {
                    sx: { position: 'absolute', bottom: '5px', opacity: mode !== 'view' ? 0.5 : 1 },
                })}
            >
                <Typography variant={'subtitle2'} color={'primary'}>
                    {`${
                        actionBtnName ??
                        `View All ${
                            widgetData?.widgetTypeId === 'energy_flow' ? 'Assets' : widgetData?.widgetTypeName ?? ''
                        }`
                    } `}
                </Typography>
                <IconButton
                    sx={{ position: 'absolute', right: '12px' }}
                    disabled={mode === 'preview' || mode !== 'view' || disabled}
                    aria-label="settings"
                    onClick={(): void => {
                        if (widgetData?.route) {
                            navigate(
                                `${modUrl?.url ?? widgetData?.route?.url}${
                                    widgetData?.route?.state ? `#${widgetData?.route?.state}` : ''
                                }`,
                                {
                                    state: {
                                        selectedTab: modUrl?.state ?? widgetData?.route?.state ?? '' ?? ids,
                                        widgetId: widgetData?.id,
                                    },
                                }
                            );
                        }
                    }}
                >
                    <NavigateNext fontSize="medium" />
                </IconButton>
            </Stack>
        </CardActions>
    );
};
