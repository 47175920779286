import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleAssetTypes, handleChannelRemove } from './helpers';

const aggregatedTrendsInitialState: any = {
    assetTypes: {},
    type: 'bar',
    maxCount: 6,
    unitType: 'kWh',
    secondary: {},
    assets: [],
    viewTitleBar: true,
    viewAllButton: true,
    selectedTimePeriod: 'today',
    startTime: 0,
    endTime: 0,
    widgetTypeId: 'aggregated_trends',
    downloadType: 'CSV',
    dateFormat: 'MM/DD/YYYY',
    timeZone: null,
};

const aggregatedTrendsWidgetSlice = createSlice({
    name: 'aggregated_trends',
    initialState: aggregatedTrendsInitialState,
    reducers: {
        resetAggregatedTrendsConfiguration: () => {
            return aggregatedTrendsInitialState;
        },
        setAggregatedTrendsData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload ?? [];
        },
        setPredefinedAggregatedTrends: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'assetTypes') {
                    state[key] = action.payload[key];
                }
            });
        },
        setAggregatedTrendsAssetsData: (state: RootState, action: PayloadAction<any>) => {
            state.assetsData = action.payload;
        },
        setAggregatedTrendsAssetTypes: (state: RootState, action: PayloadAction<any>) => {
            handleAssetTypes(state, action);
        },
        reAllocateAggregatedTrendsAssets: (state: RootState, action: PayloadAction<any>) => {
            const movedItem = state['assetTypes'][action.payload?.type]?.splice(action.payload?.sourceIndex, 1);
            state['assetTypes'][action.payload?.type]?.splice(action.payload.destinationIndex, 0, ...movedItem);
        },
        setAggregatedTrendsPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        setAggregatedTrendsAssets: (state, action) => {
            const { id } = action.payload;
            const existingId = state['assets'].findIndex((el: any) => el.id === id);
            if (existingId !== -1) {
                state['assets'].splice(existingId, 1);
            } else {
                state['assets'].push(action.payload);
            }
        },
        removeAssetsForAggregatedTrends: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
            state['assets'] = [];
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                state.secondary = [];
                state['assets'] = [];
            }
        });
    },
});

export const {
    setAggregatedTrendsAssetTypes,
    setAggregatedTrendsPreferences,
    resetAggregatedTrendsConfiguration,
    setAggregatedTrendsData,
    reAllocateAggregatedTrendsAssets,
    setAggregatedTrendsAssets,
    setPredefinedAggregatedTrends,
    setAggregatedTrendsAssetsData,
    removeAssetsForAggregatedTrends,
} = aggregatedTrendsWidgetSlice.actions;

export default aggregatedTrendsWidgetSlice;
