import { Refresh, GetApp } from '@mui/icons-material';
import { FormControl, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import React, { useState } from 'react';

export const WidgetHeader = ({
    mode,
    widgetData,
    sortedData,
    count,
    widgetFilter,
    fetchWeatherData,
    downloadCSVHandler,
    widgetTypeId,
}: {
    mode: string | undefined;
    widgetData: any;
    sortedData?: any;
    count?: number;
    headerAction?: any;
    widgetFilter?: any;
    fetchWeatherData?: any;
    downloadCSVHandler?: any;
    widgetTypeId?: string;
}): JSX.Element => {
    const [lastUpdatedTimePeriod, setLastUpdateTimePeriod] = useState<any>(0);
    const [timePeriod, setTimePeriod] = useState(widgetData?.selectedTimePeriod);
    const [initialTime, setInitialTime] = useState<string>(new Date().toISOString());

    // Function to update last updated time
    const updateLastUpdatedTime = (time: string): void => {
        setLastUpdateTimePeriod(formatDistanceToNow(new Date(time), { addSuffix: true }));
    };

    React.useEffect(() => {
        if (widgetData?.widgetTypeId === 'weather') {
            updateLastUpdatedTime(initialTime);

            const intervalId = setInterval(() => {
                updateLastUpdatedTime(initialTime);
            }, 60000);

            return (): void => clearInterval(intervalId);
        }
    }, [widgetData, initialTime]);

    const handleRefresh = (id: any): void => {
        fetchWeatherData(id);
        const newInitialTime = new Date().toISOString();
        setInitialTime(newInitialTime);
        updateLastUpdatedTime(newInitialTime);

        clearInterval((window as any).lastUpdatedInterval);
        (window as any).lastUpdatedInterval = setInterval(() => {
            updateLastUpdatedTime(newInitialTime);
        }, 60000);
    };

    const handleDisableForWeatherWidget = (): boolean => {
        if (widgetData?.widgetTypeId === 'weather') {
            if (Object.keys(widgetData?.secondary || {})?.length) {
                return false;
            }
        }
        return true;
    };

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={
                mode?.includes('edit')
                    ? {
                          position: 'absolute',
                          top: '0px',
                          height: '48px',
                          left: widgetTypeId === 'command_bar' && mode === 'pinned-edit' ? '16px' : '50px',
                      }
                    : { height: '48px', padding: '16px' }
            }
        >
            <Typography variant="subtitle2" className="text-primary" fontWeight={'600'} fontSize={'14px'}>
                {widgetData?.widgetName}
                {widgetData?.isTotalCountVisible && (count || sortedData?.secondary?.length) ? (
                    <span>{`( ${count ?? sortedData?.secondary?.length} )`}</span>
                ) : (
                    <></>
                )}
            </Typography>
            {(widgetData?.viewTimePeriod || widgetData?.widgetTypeId === 'aggregated_trends') && (
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <FormControl variant="standard">
                        <Select
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={timePeriod}
                            onChange={(event): void => {
                                widgetFilter(event, widgetData?.id);
                                setTimePeriod(event.target.value);
                            }}
                            sx={{ '& .MuiSelect-select': { paddingLeft: '12px' } }}
                            disabled={mode !== 'view'}
                        >
                            <MenuItem value="today">Today</MenuItem>
                            <MenuItem value="week">Week</MenuItem>
                            <MenuItem value="month">Month</MenuItem>
                            <MenuItem value="year">Year</MenuItem>
                        </Select>
                    </FormControl>
                    {widgetData?.widgetTypeId === 'aggregated_trends' && (
                        <IconButton
                            onClick={(): void => {
                                downloadCSVHandler(widgetData?.id);
                            }}
                            disabled={mode !== 'view'}
                        >
                            <GetApp />
                        </IconButton>
                    )}
                </Stack>
            )}

            {widgetData?.widgetTypeId === 'weather' && (
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        {widgetData?.displayLastUpdate && (
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Typography
                                    variant="body2"
                                    fontWeight={'300'}
                                    className={mode !== 'view' ? 'text-disabled' : 'black-500'}
                                >
                                    Last Upd:
                                    <span className={`fw-400 ${mode !== 'view' ? 'text-disabled' : 'black-500'}`}>
                                        {lastUpdatedTimePeriod}
                                    </span>
                                </Typography>
                                <IconButton
                                    disabled={mode !== 'view'}
                                    onClick={(): void => handleRefresh(widgetData?.id)}
                                >
                                    <Refresh />
                                </IconButton>
                            </Stack>
                        )}
                        {widgetData?.displayForecastPeriod && (
                            <FormControl variant="standard">
                                <Select
                                    disableUnderline
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={timePeriod}
                                    onChange={(event): void => {
                                        widgetFilter(event, widgetData?.id);
                                        setTimePeriod(event.target.value);
                                    }}
                                    sx={{ '& .MuiSelect-select': { paddingLeft: '12px' } }}
                                    disabled={mode !== 'view' || handleDisableForWeatherWidget()}
                                >
                                    <MenuItem value="today">Today</MenuItem>
                                    <MenuItem value="week">7 Days</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
