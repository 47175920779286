import React from 'react';
import { CorporateFare } from '@mui/icons-material';
import { Avatar, Badge, Box, Card, List, Stack, Tooltip, Typography } from '@mui/material';
import { findAnglesOfTriangle } from 'utils/helpers';
import { makeStyles, createStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common';
import CustomIcon from 'components/CustomIcon';

const EnergyFlowWidget = (props: any): JSX.Element => {
    const navigate = useNavigate();
    const { deviceId } = useParams();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const gridRef = React.useRef<HTMLDivElement | null>(null);
    const { assetsCount, isAssetsTypeDisplayed, widgetData } = props;

    const previewWidgetData = widgetData?.secondary?.assetNode?.map((item: any) => ({
        id: item?.id,
        count: item?.count,
        icon: (
            <Avatar className="text-white height-40 width-40" sx={{ backgroundColor: item?.activeColor }}>
                <CustomIcon iconName={item?.['icon']?.web?.name} family={item?.['icon']?.web?.family} />
            </Avatar>
        ),
        displayName: item?.displayName,
        value: item?.power,
        assets: item?.assets,
        unit: item?.unit,
        nodeType: item?.type, //receiver , RECEIVER
        activeColor: item?.activeColor,
    }));

    const sortAssetsWithOtherAtEnd = (assetsArray: any): any =>
        assetsArray.sort((currentAsset: any, nextAsset: any) => {
            if (currentAsset.id === 'Other Assets') return 1;
            if (nextAsset.id === 'Other Assets') return -1;
            return 0;
        });

    const [energyFlowWidgetData, setEnergyFlowWidgetData] = React.useState(
        sortAssetsWithOtherAtEnd(previewWidgetData)?.slice(0, assetsCount)
    );
    const [gridMeasuresState, setGridMeasuresState] = React.useState<any>({});
    const resizeComponent = (): void => {
        const gridMeasures = gridRef.current?.getBoundingClientRect();
        if (gridMeasures) {
            setGridMeasuresState(findAnglesOfTriangle(gridMeasures.height / 3, gridMeasures.width / 3));
        }
    };
    // Use a separate function to update energyFlowWidgetData based on assetsCount
    const updateEnergyFlowWidgetData = (count: any): void => {
        const newData = previewWidgetData?.slice(0, count); // Assuming initialData is your original data
        setEnergyFlowWidgetData(newData);
    };

    React.useEffect(() => {
        updateEnergyFlowWidgetData(assetsCount);
    }, [assetsCount, widgetData]);
    React.useEffect(() => {
        if (gridRef?.current) {
            resizeComponent();
            new ResizeObserver(resizeComponent).observe(gridRef.current);
        }
    }, [props]);

    const GRID_DATA_MAPPING: any = {
        '1': [2],
        '2': [2, 7],
        '3': [2, 6, 8],
        '4': [1, 3, 6, 8],
        '5': [1, 3, 4, 6, 8],
        '6': [1, 3, 4, 5, 6, 8],
        '7': [1, 2, 3, 4, 5, 6, 8],
        '8': [1, 2, 3, 4, 5, 6, 7, 8],
    };

    const useStyles = makeStyles(() => {
        const firstGridIndex = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()]?.findIndex(
            (item: any) => item === 1
        );
        const secondGridIndex = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()]?.findIndex(
            (item: any) => item === 2
        );
        const thirdGridIndex = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()]?.findIndex(
            (item: any) => item === 3
        );
        const fourthGridIndex = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()]?.findIndex(
            (item: any) => item === 4
        );
        const fifthGridIndex = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()]?.findIndex(
            (item: any) => item === 5
        );
        const sixthGridIndex = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()]?.findIndex(
            (item: any) => item === 6
        );
        const seventhGridIndex = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()]?.findIndex(
            (item: any) => item === 7
        );
        const eightGridIndex = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()]?.findIndex(
            (item: any) => item === 8
        );

        return createStyles({
            dot1: {
                animation: `$firstDot 6s linear infinite`,
            },
            dot2: {
                animation: `$secondDot 6s linear infinite`,
            },
            dot3: {
                animation: `$thirdDot 6s linear infinite`,
            },
            dot4: {
                animation: `$fourthDot 6s linear infinite`,
            },
            dot5: {
                animation: `$fifthDot 6s linear infinite`,
            },
            dot6: {
                animation: `$sixthDot 6s linear infinite`,
            },
            dot7: {
                animation: `$seventhDot 6s linear infinite`,
            },
            dot8: {
                animation: `$eightDot 6s linear infinite`,
            },

            ...(firstGridIndex !== -1
                ? {
                      '@keyframes firstDot': (function (): any {
                          const gridMeasures = gridRef.current?.getBoundingClientRect();
                          const gridHeight = (gridMeasures?.height ?? 0) / 3;
                          const gridWidth = (gridMeasures?.width ?? 0) / 3;

                          const firstTop = gridHeight / 2 + 2;
                          const firstLeft = gridWidth * (3 / 4);

                          const secondTop = gridHeight / 2 + 2;
                          const secondLeft = gridWidth;

                          const thirdTop = gridHeight + gridHeight / 2;
                          const thirdLeft = gridWidth + gridWidth / 2;
                          return {
                              [energyFlowWidgetData?.[firstGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '100%' : '0%']: {
                                  top: firstTop - 5,
                                  left: firstLeft - 5,
                              },
                              [energyFlowWidgetData?.[firstGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '70%' : '30%']: {
                                  top: secondTop - 5,
                                  left: secondLeft - 5,
                              },
                              [energyFlowWidgetData?.[firstGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '0%' : '100%']: {
                                  top: thirdTop - 5,
                                  left: thirdLeft - 5,
                              },
                          };
                      })(),
                  }
                : {
                      '@keyframes firstDot': {
                          '0%': {
                              top: 0,
                              left: 0,
                          },
                          '100%': {
                              top: 0,
                              left: 0,
                          },
                      },
                  }),
            ...(secondGridIndex !== -1
                ? {
                      '@keyframes secondDot': (function (): any {
                          const gridMeasures = gridRef.current?.getBoundingClientRect();
                          const gridHeight = (gridMeasures?.height ?? 0) / 3;
                          const gridWidth = (gridMeasures?.width ?? 0) / 3;

                          const firstTop = gridHeight / 2;
                          const firstLeft = gridWidth * (3 / 4);

                          const secondTop = gridHeight / 2;
                          const secondLeft = gridWidth * (3 / 2);
                          return {
                              [energyFlowWidgetData?.[secondGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '100%' : '0%']: {
                                  top: firstTop - 5,
                                  left: firstLeft - 5,
                              },
                              [energyFlowWidgetData?.[secondGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '0%' : '100%']: {
                                  top: secondTop - 5,
                                  left: secondLeft - 5,
                              },
                          };
                      })(),
                  }
                : {
                      '@keyframes secondDot': {
                          '0%': {
                              top: 0,
                              left: 0,
                          },
                          '100%': {
                              top: 0,
                              left: 0,
                          },
                      },
                  }),
            ...(thirdGridIndex !== -1
                ? {
                      '@keyframes thirdDot': (function (): any {
                          const gridMeasures = gridRef.current?.getBoundingClientRect();
                          const gridHeight = (gridMeasures?.height ?? 0) / 3;
                          const gridWidth = (gridMeasures?.width ?? 0) / 3;

                          const firstTop = gridHeight / 2 + 2;
                          const firstLeft = gridWidth * (3 / 4);

                          const secondTop = gridHeight / 2 + 2;
                          const secondLeft = gridWidth;

                          const thirdLeft = gridWidth + gridWidth / 2;
                          const thirdTop = -gridHeight / 2;
                          return {
                              [energyFlowWidgetData?.[thirdGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '100%' : '0%']: {
                                  top: firstTop - 5,
                                  left: firstLeft - 5,
                              },
                              [energyFlowWidgetData?.[thirdGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '70%' : '30%']: {
                                  top: secondTop - 5,
                                  left: secondLeft - 5,
                              },
                              [energyFlowWidgetData?.[thirdGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '0%' : '100%']: {
                                  top: thirdTop - 5,
                                  left: thirdLeft - 5,
                              },
                          };
                      })(),
                  }
                : {
                      '@keyframes thirdDot': {
                          '0%': {
                              top: 0,
                              left: 0,
                          },
                          '100%': {
                              top: 0,
                              left: 0,
                          },
                      },
                  }),
            ...(fourthGridIndex !== -1
                ? {
                      '@keyframes fourthDot': (function (): any {
                          const gridMeasures = gridRef.current?.getBoundingClientRect();
                          const gridHeight = (gridMeasures?.height ?? 0) / 3;
                          const gridWidth = (gridMeasures?.width ?? 0) / 3;

                          const firstTop = (gridHeight * 3) / 4;
                          const firstLeft = gridWidth / 2;

                          const secondTop = gridHeight * (3 / 2);
                          const secondLeft = gridWidth / 2;
                          return {
                              [energyFlowWidgetData?.[fourthGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '100%' : '0%']: {
                                  top: firstTop - 5,
                                  left: firstLeft - 6,
                              },
                              [energyFlowWidgetData?.[fourthGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '0%' : '100%']: {
                                  top: secondTop - 5,
                                  left: secondLeft - 5,
                              },
                          };
                      })(),
                  }
                : {
                      '@keyframes fourthDot': {
                          '0%': {
                              top: 0,
                              left: 0,
                          },
                          '100%': {
                              top: 0,
                              left: 0,
                          },
                      },
                  }),
            ...(fifthGridIndex !== -1
                ? {
                      '@keyframes fifthDot': (function (): any {
                          const gridMeasures = gridRef.current?.getBoundingClientRect();
                          const gridHeight = (gridMeasures?.height ?? 0) / 3;
                          const gridWidth = (gridMeasures?.width ?? 0) / 3;

                          const firstTop = gridHeight / 5;
                          const firstLeft = gridWidth / 2;

                          const secondTop = -gridHeight / 2;
                          const secondLeft = gridWidth / 2;
                          return {
                              [energyFlowWidgetData?.[fifthGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '100%' : '0%']: {
                                  top: firstTop - 5,
                                  left: firstLeft - 5,
                              },
                              [energyFlowWidgetData?.[fifthGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '0%' : '100%']: {
                                  top: secondTop - 5,
                                  left: secondLeft - 5,
                              },
                          };
                      })(),
                  }
                : {
                      '@keyframes fifthDot': {
                          '0%': {
                              top: 0,
                              left: 0,
                          },
                          '100%': {
                              top: 0,
                              left: 0,
                          },
                      },
                  }),
            ...(sixthGridIndex !== -1
                ? {
                      '@keyframes sixthDot': (function (): any {
                          const gridMeasures = gridRef.current?.getBoundingClientRect();
                          const gridHeight = (gridMeasures?.height ?? 0) / 3;
                          const gridWidth = (gridMeasures?.width ?? 0) / 3;

                          const firstTop = gridHeight / 2 + 2;
                          const firstLeft = gridWidth / 4;

                          const secondTop = gridHeight / 2 + 2;
                          const secondLeft = 0;

                          const thirdTop = gridHeight + gridHeight / 2;
                          const thirdLeft = -gridWidth / 2;
                          return {
                              [energyFlowWidgetData?.[sixthGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '100%' : '0%']: {
                                  top: firstTop - 5,
                                  left: firstLeft - 5,
                              },
                              [energyFlowWidgetData?.[sixthGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '70%' : '30%']: {
                                  top: secondTop - 5,
                                  left: secondLeft - 5,
                              },
                              [energyFlowWidgetData?.[sixthGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '0%' : '100%']: {
                                  top: thirdTop - 5,
                                  left: thirdLeft - 5,
                              },
                          };
                      })(),
                  }
                : {
                      '@keyframes sixthDot': {
                          '0%': {
                              top: 0,
                              left: 0,
                          },
                          '100%': {
                              top: 0,
                              left: 0,
                          },
                      },
                  }),
            ...(seventhGridIndex !== -1
                ? {
                      '@keyframes seventhDot': (function (): any {
                          const gridMeasures = gridRef.current?.getBoundingClientRect();
                          const gridHeight = (gridMeasures?.height ?? 0) / 3;
                          const gridWidth = (gridMeasures?.width ?? 0) / 3;

                          const firstTop = gridHeight / 2;
                          const firstLeft = gridWidth / 4;

                          const secondTop = gridHeight / 2;
                          const secondLeft = -gridWidth / 2;
                          return {
                              [energyFlowWidgetData?.[seventhGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '100%' : '0%']:
                                  {
                                      top: firstTop - 5,
                                      left: firstLeft - 5,
                                  },
                              [energyFlowWidgetData?.[seventhGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '0%' : '100%']:
                                  {
                                      top: secondTop - 5,
                                      left: secondLeft - 5,
                                  },
                          };
                      })(),
                  }
                : {
                      '@keyframes seventhDot': {
                          '0%': {
                              top: 0,
                              left: 0,
                          },
                          '100%': {
                              top: 0,
                              left: 0,
                          },
                      },
                  }),
            ...(eightGridIndex !== -1
                ? {
                      '@keyframes eightDot': (function (): any {
                          const gridMeasures = gridRef.current?.getBoundingClientRect();
                          const gridHeight = (gridMeasures?.height ?? 0) / 3;
                          const gridWidth = (gridMeasures?.width ?? 0) / 3;

                          const firstTop = gridHeight / 2 + 2;
                          const firstLeft = gridWidth / 4;

                          const secondTop = gridHeight / 2 + 2;
                          const secondLeft = 0;

                          const thirdTop = -(gridHeight / 2);
                          const thirdLeft = -gridWidth / 2;
                          return {
                              [energyFlowWidgetData?.[eightGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '100%' : '0%']: {
                                  top: firstTop - 5,
                                  left: firstLeft - 5,
                              },
                              [energyFlowWidgetData?.[eightGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '70%' : '30%']: {
                                  top: secondTop - 5,
                                  left: secondLeft - 5,
                              },
                              [energyFlowWidgetData?.[eightGridIndex ?? 0]?.nodeType === 'RECEIVER' ? '0%' : '100%']: {
                                  top: thirdTop - 5,
                                  left: thirdLeft - 5,
                              },
                          };
                      })(),
                  }
                : {
                      '@keyframes eightDot': {
                          '0%': {
                              top: 0,
                              left: 0,
                          },
                          '100%': {
                              top: 0,
                              left: 0,
                          },
                      },
                  }),
        });
    });

    const classes: any = useStyles();

    const STYLES_MAPPING = (item: any): object[] => [
        {
            sx: { left: 0 },
            className: 'widget-top left-top',
            direction: 'row',
            childJustifyContent: 'flex-end',
            lineSx: {
                transform: `skew(0deg, ${gridMeasuresState.alpha}deg)`,
                transformOrigin: 'left',
                borderRadius: '2px',
            },
        },
        {
            sx: { left: 0 },
            className: 'widget-center',
            direction: 'row',
            childJustifyContent: 'flex-end',
            lineSx: {
                width: `${(gridMeasuresState.base * 3) / 2}px`,
                borderTop: `1px solid ${item?.activeColor}`,
                left: '75%',
            },
        },
        {
            sx: { left: 0 },
            className: 'widget-bottom left-bottom',
            direction: 'row',
            childJustifyContent: 'flex-end',
            lineSx: { transform: `skew(0deg, ${-gridMeasuresState.alpha}deg)`, transformOrigin: 'left' },
        },
        {
            sx: { left: '33.33%' },
            className: 'widget-top',
            direction: 'column',
            justifyContent: 'center',
            lineSx: {
                top: `${gridMeasuresState.perpendicular * 0.8}px`,
                height: `${gridMeasuresState.perpendicular / 2}px`,
                border: `1px solid ${item?.activeColor}`,
            },
        },
        {
            sx: { left: '33.33%' },
            className: 'widget-bottom',
            direction: 'column',
            justifyContent: 'center',
            lineSx: {
                bottom: `${gridMeasuresState.perpendicular * 0.8}px`,
                height: `${gridMeasuresState.perpendicular / 2}px`,
                border: `1px solid ${item?.activeColor}`,
            },
        },
        {
            sx: { left: '66.66%' },
            className: 'widget-top right-top',
            direction: 'row-reverse',
            childJustifyContent: 'flex-start',
            lineSx: {
                transform: `skew(0deg, ${-gridMeasuresState.alpha}deg)`,
                transformOrigin: 'right',
                borderRadius: '16px',
            },
        },
        {
            sx: { left: '66.66%' },
            className: 'widget-center',
            direction: 'row-reverse',
            childJustifyContent: 'flex-start',
            lineSx: {
                width: `${(gridMeasuresState.base * 3) / 2}px`,
                borderTop: `1px solid ${item?.activeColor}`,
                right: '75%',
            },
        },
        {
            sx: { left: '66.66%' },
            className: 'widget-bottom right-bottom',
            direction: 'row-reverse',
            childJustifyContent: 'flex-start',
            lineSx: {
                transform: `skew(0deg, ${gridMeasuresState.alpha}deg)`,
                transformOrigin: 'right',
                borderRadius: '24px',
            },
        },
    ];

    return (
        <Stack className="energy-flow-wrapper" direction={'row'} ref={gridRef}>
            {energyFlowWidgetData?.map((item: any, index: any) => {
                const itemStyleId = GRID_DATA_MAPPING[energyFlowWidgetData?.length.toString()][index];
                const itemStyle: any = STYLES_MAPPING(item)[itemStyleId - 1];
                const dotClass = `dot${itemStyleId}`;
                return (
                    <Stack
                        key={item.id}
                        direction={itemStyle.direction}
                        alignItems={'center'}
                        justifyContent={itemStyle.justifyContent ?? 'space-between'}
                        className={itemStyle.className}
                        sx={itemStyle.sx}
                    >
                        <Stack
                            className="flex-70"
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={itemStyle.childJustifyContent}
                        >
                            <Tooltip
                                placement="bottom"
                                slotProps={{
                                    tooltip: {
                                        sx: {
                                            color: '#514E6A',
                                            backgroundColor: 'transparent',
                                        },
                                    },
                                }}
                                title={
                                    <Card
                                        sx={{
                                            zIndex: 12,
                                        }}
                                    >
                                        <Stack
                                            p={2}
                                            className="border-bottom-1 cursor-pointer"
                                            direction={'row'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            onClick={(): void =>
                                                navigate(
                                                    deviceId
                                                        ? `/${currentRealmName}/energyFlowDetails/${deviceId}`
                                                        : `/${currentRealmName}/energyFlowDetails`,
                                                    {
                                                        state: {
                                                            deviceTypeId:
                                                                item?.id === 'Other Assets'
                                                                    ? item?.assets?.map(
                                                                          (asset: any) => asset?.deviceTypeId
                                                                      )
                                                                    : [item?.id],
                                                        },
                                                    }
                                                )
                                            }
                                        >
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                {item.icon}
                                                {/* <Utility className="color-content" /> */}
                                                <Stack>
                                                    <Typography variant="body1" fontSize={'14px'}>
                                                        {item?.displayName ?? '--'}
                                                    </Typography>
                                                    {/* <Stack direction={'row'} alignItems={'center'}>
                                                        <Breadcrumbs separator="<" aria-label="breadcrumb">
                                                            <GroupPath
                                                                pathName={item?.groupPath ?? []}
                                                                tooltip={true}
                                                            />
                                                        </Breadcrumbs>
                                                    </Stack> */}
                                                </Stack>
                                            </Stack>
                                            <Stack justifyContent={'flex-start'} alignItems={'center'} pl={10}>
                                                <Typography variant="body2" fontSize={'14px'} fontWeight={'700'}>
                                                    {`${item?.value ?? '--'} ${item?.unit ?? '--'}`}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <List disablePadding className="max-height-150 overflow-y-scroll">
                                            {item?.assets?.map((subItem: any) => (
                                                <Stack
                                                    p={2}
                                                    key={subItem?.id}
                                                    direction={'row'}
                                                    alignItems={'center'}
                                                    justifyContent={'space-between'}
                                                >
                                                    <Typography variant="body1" fontSize={'14px'}>
                                                        {subItem?.name ?? '--'}
                                                    </Typography>
                                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                        <Typography
                                                            variant="body1"
                                                            fontSize={'14px'}
                                                            fontWeight={'400'}
                                                        >
                                                            {subItem?.power ?? '--'}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            fontSize={'14px'}
                                                            fontWeight={'300'}
                                                        >
                                                            {item?.unit ?? '--'}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            ))}
                                        </List>
                                    </Card>
                                }
                            >
                                <Stack alignItems={'center'} justifyContent={'center'}>
                                    {isAssetsTypeDisplayed ? (
                                        <Badge badgeContent={item.count} className="custom-badge">
                                            {item.icon}
                                        </Badge>
                                    ) : (
                                        <>{item.icon}</>
                                    )}
                                    <Typography variant="caption" fontSize={'12px'}>
                                        {item?.displayName ?? '--'}
                                    </Typography>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                            {item.value}
                                        </Typography>
                                        <Typography fontWeight={'300'} fontSize={'14px'}>
                                            {item.unit}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Tooltip>
                        </Stack>
                        <React.Fragment>
                            {itemStyle.lineSx?.transformOrigin && (
                                <Box
                                    className="w-25"
                                    sx={{
                                        borderTop: `1px solid ${item?.activeColor}`,
                                        position: 'relative',
                                        left: `${itemStyle.lineSx?.transformOrigin === 'left' ? '2px' : '-2px'}`,
                                        top: `${itemStyle.lineSx?.transformOrigin === 'left' ? '2px' : '1px'}`,
                                    }}
                                />
                            )}
                            {item?.nodeType !== 'OFFLINE' && item?.nodeType !== 'NONE' && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        height: '10px',
                                        width: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: '#007bc1',
                                    }}
                                    className={classes[dotClass]}
                                />
                            )}

                            <Box
                                sx={{
                                    position: 'absolute',
                                    border: `2px solid ${item?.activeColor}`,
                                    ...(itemStyle.lineSx?.transformOrigin
                                        ? {
                                              [itemStyle.lineSx?.transformOrigin]: `${
                                                  (gridMeasuresState.base ?? 0) * 2
                                              }px`,
                                              width: `${gridMeasuresState.base ?? 0}px`,
                                              top: `${gridMeasuresState.perpendicular / 2}px`,
                                          }
                                        : {}),
                                    ...itemStyle.lineSx,
                                }}
                            />
                        </React.Fragment>
                    </Stack>
                );
            })}
            <Stack
                className="organization-wrapper"
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ left: '33.33%' }}
            >
                <Stack
                    className="organization"
                    sx={{ backgroundColor: widgetData?.secondary?.buildingNode?.activeColor }}
                >
                    <Stack alignItems={'center'} justifyContent={'center'} className="center">
                        <CorporateFare
                            className="height-28 width-28"
                            sx={{
                                color:
                                    widgetData?.secondary?.buildingNode?.activeColor === '#E0EEF8' ? '#007BC1' : '#fff',
                            }}
                        />
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Typography
                                fontSize={'12px'}
                                fontWeight={'600'}
                                sx={{
                                    color:
                                        widgetData?.secondary?.buildingNode?.activeColor === '#727E84'
                                            ? '#fff'
                                            : '#424E54',
                                }}
                            >
                                {parseFloat(widgetData?.secondary?.buildingNode?.power).toFixed(3)}
                            </Typography>
                            <Typography
                                fontSize={'12px'}
                                fontWeight={'300'}
                                sx={{
                                    color:
                                        widgetData?.secondary?.buildingNode?.activeColor === '#727E84'
                                            ? '#fff'
                                            : '#424E54',
                                }}
                            >
                                {widgetData?.secondary?.buildingNode?.unit}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EnergyFlowWidget;
