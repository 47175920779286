import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleAssetTypes, handleChannelRemove } from './helpers';

const consumptionBreakdownInitialState: any = {
    assetTypes: {},
    type: 'bar',
    maxCount: 6,
    secondary: [],
    assets: [],
    viewTitleBar: true,
    viewAllButton: true,
    viewTimePeriod: true,
    selectedTimePeriod: 'today',
    isDataSortDesc: true,
};

const consumptionBreakdownWidgetSlice = createSlice({
    name: 'consumption_breakdown',
    initialState: consumptionBreakdownInitialState,
    reducers: {
        resetConsumptionConfiguration: () => {
            return consumptionBreakdownInitialState;
        },
        setBreakdownAssets: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload ?? [];
        },
        setPredefinedConsumptionBreakdown: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'assetTypes') {
                    state[key] = action.payload[key];
                }
            });
        },
        setConsumptionBreakdownAssetsData: (state: RootState, action: PayloadAction<any>) => {
            state.assetsData = action.payload;
        },
        setConsumptionAssetTypes: (state: RootState, action: PayloadAction<any>) => {
            handleAssetTypes(state, action);
        },
        reAllocateConsumptionAssets: (state: RootState, action: PayloadAction<any>) => {
            const movedItem = state['assetTypes']?.splice(action.payload?.sourceIndex, 1);
            state['assetTypes']?.splice(action.payload.destinationIndex, 0, ...movedItem);
        },
        setConsumptionPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        setConsumptionAssets: (state, action) => {
            const { id } = action.payload;
            const existingId = state['assets'].findIndex((el: any) => el.id === id);
            if (existingId !== -1) {
                state['assets'].splice(existingId, 1);
            } else {
                state['assets'].push(action.payload);
            }
        },
        removeAssetsForConsumptionBreakdown: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
            state['assets'] = [];
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                state.secondary = [];
                state['assets'] = [];
            }
        });
    },
});

export const {
    setConsumptionAssetTypes,
    setConsumptionPreferences,
    resetConsumptionConfiguration,
    setBreakdownAssets,
    reAllocateConsumptionAssets,
    setConsumptionAssets,
    setPredefinedConsumptionBreakdown,
    setConsumptionBreakdownAssetsData,
    removeAssetsForConsumptionBreakdown,
} = consumptionBreakdownWidgetSlice.actions;

export default consumptionBreakdownWidgetSlice;
