import React, { useEffect, useRef, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import logo from '../assets/images/eaton-logo1.png';
import oktaConfig from '../oktaConfig';
import { useNavigate } from 'react-router-dom';
import { BackdropLoader, NeedHelpScreen, PrivacyPolicy } from './';
import { useGetOpenRealmSettingsQuery } from '@fiji/common/src/features/realmManagement/realmApi';
import { RootState, useTypedSelector } from '@fiji/common/src/app/store';
import { getRealmNameFromSuffix } from '../utils/helpers';

/**
 * The above code is a TypeScript React component that renders a custom login form using the Okta
 * Sign-In Widget and includes additional features such as custom buttons for sign up, help, and
 * privacy policy.
 * @returns The `CustomLoginComponent` function returns a JSX element.
 */
export const CustomLoginComponent = (): JSX.Element => {
    const { oktaAuth, authState } = useOktaAuth();
    const currentRealmName = useTypedSelector((state: RootState) => state.common.selectedRealm);
    const isSuperRealm = useTypedSelector((state: RootState) => state.common.isSuperRealm);
    const widgetRef = useRef(null);
    const navigate = useNavigate();
    const policyRef = useRef<any>(null);

    const [widgetReady, setWidgetReady] = React.useState(false);
    const [isContactScreenOpen, setIsContactScreenOpen] = useState(false);

    // Fetch otp and state from query params from email callback verification URI
    // Application should have http://localhost:8080/login as the email callback verification URI
    const queryParams = new URLSearchParams(window.location.search);
    const stateToken = queryParams.get('stateToken') ?? '';
    const state = queryParams.get('state') ?? '';

    const { data: realmSettings } = useGetOpenRealmSettingsQuery(getRealmNameFromSuffix(currentRealmName), {
        skip:
            !currentRealmName ||
            ['login', 'error', window?.APP_CONFIG?.SUPER_REALM, window.APP_CONFIG.SUPER_ADMIN].includes(
                currentRealmName
            ),
    }) as any;

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
    is used to initialize and configure the Okta Sign-In Widget and handle any errors that occur
    during the authentication process. */

    const customButtons: any = [];

    if (!isSuperRealm && realmSettings?.data?.registrationEnabled) {
        customButtons.push({
            title: 'Sign up',
            className: 'btn-customAuth',
            click: (): void => {
                navigate(`/${currentRealmName}/register/create-account`);
            },
        });
    }
    customButtons.push({
        title: 'Need Help?',
        click: (): void => {
            setIsContactScreenOpen(true);
        },
    });
    customButtons.push({
        title: 'Privacy Policy',
        click: (): void => {
            policyRef?.current?.modalHandler(true);
        },
    });

    useEffect((): void | (() => void) => {
        if (!widgetRef.current) {
            return;
        }
        //
        const { issuer, clientId, redirectUri, scopes } = oktaConfig;

        /* The code is creating a new instance of the OktaSignIn class and configuring it with various
        options. */
        const widget = new OktaSignIn({
            /**
             * Note: when using the Sign-In Widget for an OIDC flow, it still
             * needs to be configured with the base URL for your Okta Org. Here
             * we derive it from the given issuer for convenience.
             */
            baseUrl: issuer.split('/oauth2')[0],
            clientId,
            redirectUri,
            logo: realmSettings?.data?.avatarLogo ?? logo,
            i18n: {
                en: {
                    'primaryauth.title': ' ',
                    'primaryauth.submit': 'Log In',
                    help: 'Need Help?',
                    'password.reset': 'Forgot Password',
                    goback: 'Back',
                    'password.forgot.sendEmail': 'Send Email',
                    'oform.errorbanner.title': 'Invalid username or login. Please try again.',
                    'primaryauth.username.placeholder': 'Email Address',
                    'error.username.required': 'Please enter a valid email.',
                    'errors.E0000004': 'Invalid username or login. Please try again.',
                    'password.forgot.emailSent.desc': 'A link to reset your password has been sent to {0}',
                    'oform.back': 'Log In',
                    'password.forgot.emailSent.title': 'Forgot Password',
                    'password.forgot.email.or.username.placeholder': 'E-mail Address',
                    'password.forgot.email.or.username.tooltip': 'E-mail Address',
                    forgotpassword: 'Forgot your password?',
                },
            },
            authParams: {
                // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
                issuer,
                scopes,
                // pkce: false,
            },
            useInteractionCodeFlow: true, // Set to true, if your org is OIE enabled
            stateToken,
            state,
            customButtons,
            features: {
                showPasswordToggleOnSignInPage: true,
            },
        });

        /*  This method is used to display the sign-in form and redirect the user to the Okta authentication page for authentication. */
        widget.showSignInAndRedirect({ el: widgetRef.current }).catch(() => {});
        widget.on('afterRender', () => {
            setWidgetReady(true);
        });

        return () => widget.remove();
    }, [oktaAuth, authState]);

    return (
        <div className="Wrapper">
            <div ref={widgetRef} className={isSuperRealm ? 'super-realm-widget' : 'login-widget'} />
            <NeedHelpScreen isOpen={isContactScreenOpen} handleCloseModal={(): void => setIsContactScreenOpen(false)} />
            <PrivacyPolicy ref={policyRef} />
            {!widgetReady && <BackdropLoader isOpen showDefault={false} />}
        </div>
    );
};
