import React, { useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSortedData } from 'hooks/useSortedData';
import { WidgetHeader, WidgetFooter } from 'pages/WidgetManagement/common';
import { Card, CardContent, Divider } from '@mui/material';
// import { useTypedSelector } from '@fiji/common/src/app/store';
import WidgetNotConfigured from 'pages/EditWidget/WIdgetNotConfigured';

type TelemetryChartProps = {
    type?: 'bar' | 'column';
    widgetTypeId?: string;
    route?: string;
    state?: string;
    maxCount?: number;
    secondary: any[];
    widgetName?: string;
    [key: string]: any;
};

const Component = ({
    widgetData,
    mode,
    color,
    widgetFilter,
}: {
    widgetData: TelemetryChartProps;
    mode: string;
    color: string;
    widgetFilter?: any;
}): JSX.Element => {
    const chartWidget = JSON.parse(JSON.stringify(widgetData));
    if (chartWidget?.isDataSortDesc) {
        chartWidget?.secondary?.sort((a: { value: number }, b: { value: number }) => a?.value - b?.value);
    } else {
        chartWidget?.secondary?.reverse();
    }
    const { ids } = useSortedData(widgetData ?? [], ['secondary']);
    const chartRef: any = useRef(null);
    // const currentRealmName = useTypedSelector((storeState) => storeState.common.selectedRealm);
    const [option, setOption] = React.useState({
        chart: {
            type: 'bar',
        },
        title: {
            text: null,
        },
        xAxis: {
            title: {
                text: null,
            },
            lineWidth: 0,
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
            tickColor: '#f0f8ff00',
            minPadding: 5,
            tickInterval: 2,
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                format: '{value} KW',
                overflow: 'justify',
            },
            gridLineWidth: 0,
            tickInterval: 2,
            lineWidth: 2,
            tickColor: '#f0f8ff00',
            minPadding: 5, // Adjust this value as needed
            // maxPadding: 1, // Adjust this value as needed
        },
        plotOptions: {
            bar: {
                borderRadius: '20%',
                pointWidth: 30,
                pointPadding: 1,
                groupPadding: 1,
            },
            column: {
                borderRadius: '20%',
                pointWidth: 30,
                pointPadding: 1,
                groupPadding: 1,
            },
            series: {
                useHTML: true,
                dataLabels: {
                    enabled: true,
                    inside: true,
                    rotation: widgetData?.type === 'bar' ? 0 : -90,
                    align: 'left',
                    style: {
                        fontWeight: '100',
                    },
                    ...(widgetData?.type === 'bar' ? { y: 30 } : { x: 30 }),
                    formatter: function (this: any): string {
                        return `
    <span style="font-weight: 300; font-size: 14px;">${this.y}</span> 
    <span style="font-weight: 300; font-size: 14px;">${this.series.tooltipOptions.valueSuffix}</span> 
    <span style="font-weight: 300; margin-left: 16px; font-size: 12px;">${this.series.name}</span>
`;
                    },
                },
                pointPlacement: 'between',
            },
        },
        tooltip: {
            enabled: true,
            formatter: function (this: any) {
                return ` ${this?.series?.name}: ${this?.y} ${this?.series?.tooltipOptions?.valueSuffix} `;
            },
            followPointer: true,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: [],
    });

    useEffect(() => {
        Highcharts.setOptions({
            yAxis: {
                lineColor: chartWidget?.type === 'bar' ? '#f0ebeb' : '#ffffff',
            },
        });
    }, [widgetData?.type]);

    const getSeriesData = (): any[] =>
        chartWidget?.secondary?.slice(0, chartWidget?.maxCount ?? chartWidget?.secondary?.length).map((item: any) => ({
            name: item?.displayName ?? '',
            data: [item?.value ?? 0],
            tooltip: {
                valueSuffix: item?.unit ? ` ${item?.unit}` : '%',
            },
            color: color,
        }));

    const getIntervalValue = (dataValue: any): any => {
        const value: number = dataValue?.reduce?.(
            (max: any, current: any) => (current.value > max.value ? current : max),
            dataValue?.[0]
        )?.value;
        if (value > 10) return Math.ceil(value / 10);
        return value ? (value / 12)?.toFixed?.(2) : undefined;
    };

    useEffect(() => {
        const chart = chartRef?.current?.chart;
        if (chart) {
            const cloneChart: any = JSON.parse(JSON.stringify(option));
            cloneChart['series'] = getSeriesData() ?? [];
            cloneChart['chart'] = {
                type: chartWidget?.type,
            };
            const data: any = JSON?.parse(JSON.stringify(chartWidget?.secondary ?? []));
            cloneChart['yAxis'] = {
                ...(option?.yAxis ?? {}),
                labels: {
                    format: `{value} ${
                        data?.reduce?.((max: any, current: any) => (current.value > max.value ? current : max), data[0])
                            ?.unit ?? '%'
                    }`,
                    overflow: 'justify',
                },
                tickInterval: getIntervalValue(data),
            };
            setOption(cloneChart);
        }
    }, [widgetData]);

    return (
        <Card sx={{ height: '100%' }}>
            {(chartWidget.widgetTypeId !== 'consumption_breakdown' || chartWidget.viewTitleBar) &&
                mode !== 'viewAll' && <WidgetHeader mode={mode} widgetData={chartWidget} widgetFilter={widgetFilter} />}
            {mode !== 'edit' && <Divider />}
            <CardContent className="padding-0">
                {chartWidget?.secondary?.length ? (
                    <HighchartsReact highcharts={Highcharts} options={option} ref={chartRef} />
                ) : (
                    <WidgetNotConfigured
                        title={
                            chartWidget?.multiDevice && chartWidget?.assets?.length === 0
                                ? 'Widget Not Configured'
                                : (!chartWidget?.secondary?.length && !chartWidget?.multiDevice) ||
                                  chartWidget?.assets?.length
                                ? 'No Data Found'
                                : undefined
                        }
                    />
                )}
            </CardContent>
            {chartWidget.viewAllButton && mode !== 'viewAll' && (
                <WidgetFooter
                    mode={mode}
                    widgetData={chartWidget}
                    ids={ids}
                    disabled={!chartWidget?.secondary?.length}
                    // modUrl={{ url: `/${currentRealmName}/chart`, state: chartWidget?.type }}
                />
            )}
        </Card>
    );
};
export default Component;
