import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleChannelRemove, handleReallocateChannels, moveWithinList } from './helpers';

const chartInitialState = { type: 'bar', secondary: [], maxCount: 6, viewAllButton: false };
const chartWidgetSlice = createSlice({
    name: 'charts',
    initialState: chartInitialState,
    reducers: {
        resetChartConfiguration: () => {
            return chartInitialState;
        },
        setPredefinedChart: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                state[key] = action.payload[key];
            });
        },
        setChartChannels: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },
        reAllocateChartChannels: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        moveBetweenChartLists: (state: RootState, action: PayloadAction<any>) => {
            moveWithinList(state, action);
        },
        removeChartChannel: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action);
        },
        setChartPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) state.secondary = [];
        });
        builder.addCase('widgetConfiguration/setWidgetSource', (state) => {
            state.secondary = [];
        });
    },
});

export const {
    moveBetweenChartLists,
    reAllocateChartChannels,
    removeChartChannel,
    setChartPreferences,
    resetChartConfiguration,
    setPredefinedChart,
    setChartChannels,
} = chartWidgetSlice.actions;

export default chartWidgetSlice;
