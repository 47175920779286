// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react';
import { api } from '../app/api/baseApi';
import { useGetWidgetDetails } from './useGetWidgetDetails';
import { customEndpointSelector, isWidgetApplicable, isWidgetConfigured } from '../utils/helpers';
import { useLazyGetDashboardWidgetByIdQuery as useLazyGetWidgetByIdQuery } from '../features/widgetManagement/widgetApi';
import { useAppDispatch, useTypedSelector } from '../app/store';
import {
    setSummaryResponse,
    setSummaryWidgetDetailsLoader,
    setWidgetList,
} from '../features/dashboardManagement/commonDashboardSlice';
import { ENERGY_FLOW_WIDGET_POLLING_INTERVAL_IN_MS } from '../constants/cacheTimeout';

type ReturnProps = {
    isLoading: boolean;
};

export const useCommonWidgetConfiguration = (dashboardData: any, dataGetter: any): ReturnProps => {
    const dispatch = useAppDispatch();
    const pollingRefs = React.useRef<{ [key: string]: any }>({});
    const widgets = useTypedSelector((rootState) => rootState.commonDashboard.widgetList);
    const loadingState = useTypedSelector((rootState) => rootState.commonDashboard.loaders);
    const apiResponses = useTypedSelector((rootState) => rootState.commonDashboard.apiResponse);
    const failedPollingApis = apiResponses.filter(
        (failApi: { type: string; responses: Array<{ isError: any }> }) =>
            failApi.type === 'energy_flow' && failApi.responses[0].isError
    );
    const [getWidgetConfig, { isFetching, isLoading }] = useLazyGetWidgetByIdQuery();

    const { payload, response: widgetResponse } = useGetWidgetDetails({
        selectorType: 'commonDashboard',
        dataGetter,
    });

    function widgetConfigHandler(widgetId: any): void {
        getWidgetConfig(widgetId)
            .then((res: any) => {
                dispatch(setWidgetList({ data: res?.data?.data, widgetId: widgetId }));
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    React.useEffect(() => {
        if (dashboardData?.widgetIdList?.length) {
            dashboardData?.widgetIdList?.forEach((widgetId: any) => {
                widgetConfigHandler(widgetId);
            });
        }
    }, [dashboardData]);

    React.useEffect(() => {
        if (apiResponses?.length) {
            apiResponses.forEach(({ responses, type, widgetId }: any) => {
                if (!loadingState[widgetId]) {
                    (widgetResponse[type] || widgetResponse['default'])?.(responses, widgetId);
                }
                if (!loadingState[widgetId]) {
                    (widgetResponse[type] || widgetResponse['default'])?.(responses, widgetId);
                }
            });
        }
    }, [apiResponses]);

    React.useEffect(() => {
        if (
            widgets?.length === dashboardData?.widgetIdList?.length &&
            widgets?.every((item: any) => !loadingState[item?.id])
        ) {
            getValues();
        }
    }, [widgets, dashboardData]);

    function setWidgetValue(widget: any, endpoint: any): Promise<any> {
        const selectedWidgetConfig = widgets?.find((prevWidget: any) => prevWidget?.id === widget?.id)?.config;

        const key = `${widget?.widgetType?.id}${
            endpoint?.endpoint === 'getDeviceAlertCount' || endpoint?.endpoint === 'getAvailableCommands'
                ? `-${endpoint?.endpoint}`
                : ''
        }`;

        return new Promise((resolve) => {
            dispatch(
                (api as any)?.endpoints?.[endpoint?.endpoint]?.initiate(
                    (payload?.[key] || payload?.['default'])?.(selectedWidgetConfig),
                    {
                        forceRefetch: true,
                    }
                )
            ).then((response: any) => {
                resolve(response);
            });
        });
    }

    function getValues(): void {
        widgets?.forEach((widget: any) => {
            if (
                widget?.widgetType?.internalCdnJsLink ||
                !isWidgetApplicable(widget, dataGetter['deviceId'] ? 'DEVICE' : 'GROUP') ||
                !isWidgetConfigured(widget)
            ) {
                dispatch(setSummaryWidgetDetailsLoader({ key: widget?.id, value: 'loaded' }));
            } else if (!loadingState[widget?.id]) {
                const promises: any = [];
                const pollingEndpoints: any = [];

                for (let i = 0; i < customEndpointSelector(widget?.widgetType?.id)?.length; i++) {
                    const endpoint = customEndpointSelector(widget?.widgetType?.id, widget?.config?.type)[i];
                    promises.push(setWidgetValue(widget, endpoint));

                    if (widget?.widgetType?.id === 'energy_flow') {
                        pollingEndpoints.push(endpoint);
                    }
                }

                Promise.all(promises)
                    .then((responses) => {
                        dispatch(setSummaryResponse({ widget, responses }));
                        // Start polling only after initial API calls are resolved
                        pollingEndpoints.forEach((endpoint: any) => {
                            startEnergyFlowPolling(widget, endpoint);
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
        });
    }

    const startEnergyFlowPolling = (widget: any, endpoint: any): void => {
        // Ensure the polling interval is cleared before starting a new one
        if (pollingRefs.current[widget.id]) {
            clearInterval(pollingRefs.current[widget.id]);
        }

        pollingRefs.current[widget.id] = setInterval(() => {
            const selectedWidgetConfig = widgets?.find((prevWidget: any) => prevWidget?.id === widget?.id)?.config;
            const key = `${widget?.widgetType?.id}${
                endpoint?.endpoint === 'getDeviceAlertCount' ? `-${endpoint?.endpoint}` : ''
            }`;

            dispatch(
                (api as any)?.endpoints?.[endpoint?.endpoint]?.initiate(
                    (payload?.[key] || payload?.['default'])?.(selectedWidgetConfig),
                    {
                        forceRefetch: true,
                    }
                )
            )
                .then((response: any) => {
                    dispatch(setSummaryResponse({ widget, responses: [response] }));
                })
                .catch((error: any) => {
                    console.error(error);
                });
        }, ENERGY_FLOW_WIDGET_POLLING_INTERVAL_IN_MS);
    };

    // Cleanup on component unmount
    React.useEffect(() => {
        return () => {
            Object.values(pollingRefs.current).forEach((intervalId) => {
                clearInterval(intervalId);
            });
        };
    }, []);

    // Effect to stop specific polling if any APIs fail
    React.useEffect(() => {
        if (failedPollingApis.length) {
            failedPollingApis.forEach((failApi: { widgetId: string | number }) => {
                clearInterval(pollingRefs.current[failApi.widgetId]);
            });
        }
    }, [failedPollingApis]);

    return {
        isLoading: isLoading || isFetching,
    };
};
