import React from 'react';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with utc and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);
const ChartTooltip = ({ data, category, unit, selectedTimePeriod, timeZone }: any): React.JSX.Element => {
    const totalConsumption = data
        .reduce((acc: any, item: { consumption: any }) => acc + (item.consumption || 0), 0)
        .toFixed(3);
    const totalSupply = data.reduce((acc: any, item: { supply: any }) => acc + (item.supply || 0), 0).toFixed(3);

    const unitSpecificConsumingLabel = unit.toLowerCase() === 'w' ? 'Load' : 'Consumption';
    const unitSpecificSupplyLabel = unit.toLowerCase() === 'w' ? 'Supply' : 'Production';

    function formatTimestamp(currentTimestamp: string | number | Date): string {
        // Convert the input timestamp to the specified timezone
        const date = dayjs.utc(currentTimestamp).tz(timeZone);

        // Define options for the date part
        const formattedDate = date.format('MMM D');
        const weekday = date.format('dddd');
        const formattedTime = date.format('HH:mm');

        // Combine the formatted date, weekday, and time
        return `${formattedDate}, ${weekday} | ${formattedTime}`;
    }

    const tooltipTimeLabel = (): any => {
        if (selectedTimePeriod === 'today') {
            return formatTimestamp(category);
        }
        return category;
    };

    return (
        <div className="aggregated-tooltip">
            <div className="flex-center-between flex-100">
                <div className="align-items-center flex-50">
                    <p className="black-500 f-16">{tooltipTimeLabel()}</p>
                </div>
                <div className="flex-center-between flex-50">
                    <p className="black-500 f-14 fw-600 flex-50">{unitSpecificConsumingLabel}</p>
                    <p className="black-500 f-14 fw-600 flex-50">Supply </p>
                </div>
            </div>
            <div className="border-y-1 padding-top-16">
                {data.map(
                    (item: any) =>
                        // eslint-disable-next-line react/jsx-key
                        item.label !== 'Delta' && (
                            // eslint-disable-next-line react/jsx-key
                            <div className="flex-center-between asset-type">
                                <div className="align-items-center flex-50">
                                    <div
                                        className="height-16 width-16 border-radius-50"
                                        style={{ backgroundColor: item.color }}
                                    />
                                    <p className="black-500 f-14 margin-left-8">{`${item.label}:`}</p>
                                </div>
                                <div className="flex-center-between flex-50">
                                    <div className="align-items-center flex-50">
                                        <TrendingDown className="color-danger" />
                                        <p className="fw-300 f-16 black-500 margin-left-8">
                                            <span className="fw-600">
                                                {item.consumption ? `${item.consumption}${unit}` : '-'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="align-items-center flex-50">
                                        <TrendingUp className="text-success" />
                                        <p className="fw-300 f-16 black-500 margin-left-8">
                                            <span className="fw-600">
                                                {item.supply ? `${item.supply}${unit}` : '-'}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                )}
            </div>
            <div className="justify-content-between height-70">
                <div className="flex-50">
                    <p className="black-500 f-12">{`Total ${unitSpecificConsumingLabel}`}</p>
                    <div className="align-items-center tooltip-footer">
                        <TrendingDown className="color-danger" />
                        <p className="black-500 f-34 fw-300 margin-left-8">
                            <span className="fw-600">{totalConsumption}</span>
                            {unit}
                        </p>
                    </div>
                </div>
                <div className="flex-50">
                    <p className="black-500 f-12">{`Total ${unitSpecificSupplyLabel}`}</p>
                    <div className="align-items-center tooltip-footer">
                        <TrendingUp className="text-success" />
                        <p className="black-500 f-34 fw-300 margin-left-8">
                            <span className="fw-600">{totalSupply}</span>
                            {unit}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChartTooltip;
