import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleChannelRemove, handleReallocateChannels, moveWithinList } from './helpers';

const trendsInitialState = {
    type: 'area',
    secondary: [],
    maxCount: 6,
    viewAllButton: false,
    duration: 24,
    channelData: [],
    trendsLegendData: {},
};
const trendsWidgetSlice = createSlice({
    name: 'trends',
    initialState: trendsInitialState,
    reducers: {
        resetTrendsConfiguration: () => {
            return trendsInitialState;
        },
        setPredefinedTrends: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                state[key] = action.payload[key];
            });
        },
        setTrendsChannels: (state: RootState, action: PayloadAction<any>) => {
            const currentClone = current(state);
            const selectedIndex = currentClone?.channelData?.findIndex(
                (item: any) =>
                    item?.channelId === action.payload?.channelPayload?.channelId &&
                    item?.deviceId === action.payload?.channelPayload?.deviceId
            );
            if (selectedIndex === -1) {
                state['channelData']?.push(action.payload?.channelPayload);
            } else {
                state['channelData']?.splice(selectedIndex, 1);
            }
            channelsHandler(state, action);
        },
        reAllocateTrendsChannels: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        moveBetweenTrendsLists: (state: RootState, action: PayloadAction<any>) => {
            moveWithinList(state, action);
        },
        removeTrendsChannel: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action);
        },
        setTrendsPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        setTrendsLegendData: (state: any, action: PayloadAction<any>) => {
            state.trendsLegendData[action.payload.id] = action.payload.value;
        },
        resetTrendsLegendData: (state: any) => {
            state.trendsLegendData = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
            state.channelData = [];
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                state.secondary = [];
                state.channelData = [];
            }
        });
    },
});

export const {
    moveBetweenTrendsLists,
    reAllocateTrendsChannels,
    resetTrendsConfiguration,
    setPredefinedTrends,
    setTrendsChannels,
    removeTrendsChannel,
    setTrendsPreferences,
    setTrendsLegendData,
    resetTrendsLegendData,
} = trendsWidgetSlice.actions;

export default trendsWidgetSlice;
