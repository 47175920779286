import React from 'react';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import {
    useCreateWidgetMutation,
    useDeleteWidgetMutation,
    useUpdateWidgetMutation,
} from '@fiji/common/src/features/widgetManagement/widgetApi';
import {
    resetCloneWidgetModal,
    resetRenameWidgetModal,
    setCloneWidgetPayload,
    setRenameWidgetPayload,
} from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useRBAC } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSaveAsCount } from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';

type ReturnProps = {
    payload: any;
    isLoading: boolean;
    handleCloneModalAction: (...args: any) => void;
    handleCloneSave: (...args: any) => Promise<void>;
    handleDeleteModalAction: (...args: any) => void;
    handleDeleteConfirm: (...args: any) => Promise<void>;
    handleRenameModalAction: (...args: any) => void;
    handleRenameSave: (...args: any) => Promise<void>;
};
export const useHandleWidgetListActions = (
    selectorType: string,
    onClickCallback?: any,
    deleteModalClickCallback?: any
): ReturnProps => {
    const { t } = useTranslation();
    const location = useLocation();
    const modalPayload = useTypedSelector((state) => state.widget[selectorType]);
    const dispatch = useAppDispatch();

    const [createWidget, { isLoading }] = useCreateWidgetMutation();
    const [deleteWidget] = useDeleteWidgetMutation();
    const [updateWidget, { isLoading: updateWidgetLoader }] = useUpdateWidgetMutation();

    const navigate = useNavigate();

    const permissions = useTypedSelector(selectCurrentPermission);

    const saveAsCount = useTypedSelector((state) => state['widgetConfiguration']['saveAsCount']);

    const { hasPermission } = useRBAC(permissions);
    const canCreateUserWidgets = hasPermission('create-user-widgets');
    const canCreateSystemWidgets = hasPermission('create-system-widgets');

    React.useEffect(() => {
        if (canCreateUserWidgets && !canCreateSystemWidgets) {
            dispatch(setCloneWidgetPayload({ key: 'isSystemWidget', value: false }));
        }
    }, [canCreateSystemWidgets, canCreateUserWidgets]);

    const handleCloneModalAction = (action: boolean, data: any): void => {
        if (action && data) {
            const presetPayload = {
                categoryId: data?.categoryId ?? '',
                ...(data?.config && { config: data?.config }),
                name: `${data?.name}-copy`,
                description: data?.description,
                isSystemWidget: data?.isSystemWidget,
                ...(data?.referenceWidgetId && { referenceWidgetId: data?.referenceWidgetId }),
                sourceId: data?.sourceId,
                type: 'PREDEFINED',
                widgetTypeId: data?.widgetType?.id,
            };
            onClickCallback(data);

            for (const key in presetPayload) {
                dispatch(setCloneWidgetPayload({ key: key, value: presetPayload[key] }));
            }

            return;
        }
    };

    const handleDeleteModalAction = (action: boolean, widgetData: any): any => {
        if (action) {
            onClickCallback(widgetData);
            return;
        }
        deleteModalClickCallback(widgetData);
        return;
    };

    const handleRenameModalAction = (action: boolean, data: any): void => {
        if (action && data) {
            onClickCallback(data);
            const presetPayload: any = {
                name: data?.name,
                description: data?.description,
            };
            for (const key in presetPayload) {
                dispatch(setRenameWidgetPayload({ key: key, value: presetPayload[key] }));
            }
            return;
        }
    };

    const handleCloneSave = async (modalType: any, onCancelCallback: any): Promise<void> => {
        const modalPayloadClone = JSON.parse(JSON.stringify(modalPayload.payload));
        if (canCreateUserWidgets && !canCreateSystemWidgets) {
            modalPayloadClone.isSystemWidget = false;
        }

        const data: any = await createWidget(modalPayloadClone);
        if (data?.data?.success) {
            dispatch(resetCloneWidgetModal());
            onCancelCallback();
            dispatch(
                setMessageContent({
                    isOpen: true,
                    ...(modalType === 'save'
                        ? {
                              message: `Changes to ${data?.data?.data?.name} ${data?.data?.data?.widgetType?.id} have been saved`,
                          }
                        : { message: 'Widget cloned successfully.' }),
                })
            );
            if (modalType === 'save') {
                const splittedArr = location.pathname.split('/');
                splittedArr[splittedArr?.length - 1] = data?.data?.data?.id;

                navigate(`${splittedArr?.join('/')}`, { state: { typeId: data?.data?.data?.widgetType?.id } });

                dispatch(setSaveAsCount(saveAsCount + 1));
            }
        }
    };
    const handleDeleteConfirm = async (data: any, deleteCancelCallback?: any): Promise<void> => {
        const { error }: any = await deleteWidget(data?.id);
        if (!error) {
            dispatch(setMessageContent({ isOpen: true, message: t('WIDGETS:WIDGET_DELETE_SUCCESS') }));
            deleteCancelCallback();
        }
    };

    const handleRenameSave = async (widgetData: any, cancelCallback: any): Promise<void> => {
        const payloadClone = JSON.parse(JSON.stringify(modalPayload?.payload ?? {}));
        if (payloadClone && !payloadClone?.description?.length) {
            payloadClone['description'] = null;
        }
        const data: any = await updateWidget({ body: payloadClone, widgetId: widgetData?.id });
        if (data?.data?.success) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('WIDGETS:RENAME_WIDGET_MODAL.SUCCESS_MSG', {
                        replace: { widgetName: widgetData?.name },
                    }),
                })
            );
            dispatch(resetRenameWidgetModal());
            cancelCallback();
        }
    };

    return {
        payload: modalPayload?.payload,
        isLoading: isLoading || updateWidgetLoader,
        handleCloneSave,
        handleDeleteConfirm,
        handleRenameSave,
        handleRenameModalAction,
        handleCloneModalAction,
        handleDeleteModalAction,
    };
};
