import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleAssetTypes, handleChannelRemove } from './helpers';

const loadTrendsInitialState: any = {
    type: 'line',
    secondary: [],
    assetTypes: {},
    assets: [],
    viewTitleBar: true,
    viewAllButton: true,
    viewTimePeriod: true,
    selectedTimePeriod: 'today',
    timeZone: null,
};
const loadTrendsWidgetSlice = createSlice({
    name: 'load_trends',
    initialState: loadTrendsInitialState,
    reducers: {
        resetLoadTrendsConfiguration: () => {
            return loadTrendsInitialState;
        },
        setPredefinedLoadTrends: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'assetTypes') {
                    state[key] = action.payload[key];
                }
            });
        },
        setLoadTrendData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload ?? [];
        },
        setLoadTrendsAssetTypes: (state: RootState, action: PayloadAction<any>) => {
            handleAssetTypes(state, action);
        },
        setSelectedLoadAssets: (state, action) => {
            const { id } = action.payload;

            const existingId = state.assets.findIndex((el: any) => el.id === id);
            if (existingId !== -1) {
                state.assets.splice(existingId, 1);
            } else {
                state.assets.push(action.payload);
            }
        },
        setLoadTrendsChannels: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },
        reAllocateLoadTrendsChannels: (state: RootState, action: PayloadAction<any>) => {
            const movedItem = state['assetTypes'][action.payload?.type]?.splice(action.payload?.sourceIndex, 1);
            state['assetTypes'][action.payload?.type]?.splice(action.payload.destinationIndex, 0, ...movedItem);
        },

        removeLoadTrendsChannel: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
        setLoadTrendsPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            state.secondary = [];
            state['assets'] = [];
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                state.secondary = [];
                state['assets'] = [];
            }
        });
    },
});

export const {
    reAllocateLoadTrendsChannels,
    removeLoadTrendsChannel,
    setLoadTrendData,
    setLoadTrendsChannels,
    setPredefinedLoadTrends,
    setLoadTrendsAssetTypes,
    setLoadTrendsPreferences,
    resetLoadTrendsConfiguration,
    setSelectedLoadAssets,
} = loadTrendsWidgetSlice.actions;

export default loadTrendsWidgetSlice;
