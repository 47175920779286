// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react';
import { useGetAllChannelsQuery, useGetDeviceTrendsMutation } from '../features/deviceManagement/deviceApi';

import { getTimeInFromTo } from '../utils/helpers';

type Props = {
    sourceId: string;
    deviceId: string | undefined;
    widgetType: string;
    filter?: any;
    isFilter?: boolean;
};

type ReturnProps = {
    trends: any;
    allChannels: any;
    isLoading: boolean;
    isError: boolean;
    payloadChangeHandler: any;
    payload: any;
};

export const useGetTrends = ({ sourceId, deviceId, filter, isFilter }: Props): ReturnProps => {
    const {
        currentData: channels,
        isLoading: areChannelsLoading,
        isFetching,
        isError,
    } = useGetAllChannelsQuery(
        {
            sourceId: sourceId,
            params: {
                channelType: 'TREND',
                ...(isFilter && {
                    searchKey: 'Unit',
                    searchValue: filter,
                }),
            },
            number: true,
        },
        { skip: !sourceId }
    );

    const [getDeviceTrends, { isLoading, isError: trendsError, data }] = useGetDeviceTrendsMutation();

    const [details, setDetails] = React.useState<any>();
    const [activeDuration, setActiveDuration] = React.useState<any>(getTimeInFromTo(24, 'h'));
    const [currentPayload, setCurrentPayload] = React.useState<any>();

    React.useEffect(() => {
        if (channels?.data && deviceId && !isFetching) {
            void configurePayload();
        }
    }, [channels, deviceId]);

    React.useEffect(() => {
        if (data?.data) {
            const secondary: any = [];
            data?.data?.forEach((channelData: any) => {
                channelData?.channels?.forEach((channel: any) => {
                    secondary.push({
                        deviceId: channelData?.deviceId,
                        unit: channel?.unit,
                        data: channel.records,
                        channelId: channel?.channelId,
                    });
                });
            });
            setDetails({ secondary: secondary });
        }
        if (trendsError) {
            setDetails({ secondary: [] });
        }
    }, [data, trendsError]);

    async function configurePayload(): Promise<void> {
        const payload = [
            {
                deviceId: deviceId,
                channelId: channels?.data?.slice(0, 5).map((item: any) => item.channelId),
            },
        ];

        setCurrentPayload({ payload, ...activeDuration });
        if (channels?.data?.length) {
            await getDeviceTrends({ payload, ...activeDuration });
        } else {
            setDetails({ secondary: [] });
        }
    }

    async function payloadChangeHandler(channelIds: any, duration: any, isRange: boolean, date?: any): Promise<void> {
        let payload: any = [];
        if (isRange) payload = { payload: [{ deviceId: deviceId, channelId: channelIds }], ...date };
        else payload = { payload: [{ deviceId: deviceId, channelId: channelIds }], ...duration };
        setCurrentPayload(payload);
        if (channelIds?.length) {
            await getDeviceTrends(payload);
        } else {
            setDetails({ secondary: [] });
        }
        setActiveDuration(duration);
    }

    return {
        trends: details,
        allChannels: channels,
        isLoading: (isLoading || isFetching || areChannelsLoading) && !trendsError,
        isError: isError || trendsError,
        payloadChangeHandler,
        payload: currentPayload,
    };
};
